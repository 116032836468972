import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { ComboboxList, ComboboxOption } from '@reach/combobox';

import SuggesterItem from 'src/comps/SuggesterItem';
import ButtonReset from 'src/comps/ButtonReset';

import { fetchSuggestionsRequestAction, resetSuggestionsAction } from './actions';
import { getSuggesterSearch, getSuggestions } from './selectors';
import { SuggesterBase, Input, PopOverMobile, PopOverDesktop } from './styles';
import { useKafkaEvent } from 'src/hooks/useKafkaEvent';
import { usePage } from 'src/hooks/usePage';
import { SECTIONS } from 'src/hooks/useKafkaEvent/constants';

const Suggester = ({
  fullscreen,
  handleFocus,
  handleReset,
  handleSelect,
  language,
  market,
  placeholder = '',
  reset,
  search,
  isOpen = false,
}) => {
  const inputElement = useRef(null);
  const dispatch = useDispatch();
  const router = useRouter();
  const isDetail = router.asPath?.includes('hotel');
  const { pageId } = usePage();
  const { sendEvent } = useKafkaEvent({ page: pageId });

  const PopOver = fullscreen ? PopOverMobile : PopOverDesktop;

  const suggestions = useSelector(getSuggestions);
  const suggesterSearch = useSelector(getSuggesterSearch);
  const [searchTerm, setSearchTerm] = useState(search);

  useEffect(() => {
    if ((inputElement.current && fullscreen) || reset) {
      if (reset) {
        setSearchTerm('');
        inputElement.current.setSelectionRange(0, 0);
      }

      inputElement.current.focus();
    }
  }, [fullscreen, reset, inputElement]);

  function handleChange(event) {
    const text = event.target.value;
    setSearchTerm(text);

    if (text.length === 0) {
      handleReset();
    }

    if (text && text.length > 1) {
      dispatch(fetchSuggestionsRequestAction({ language, market, search: text }));
    }
  }

  function handleClick() {
    sendEvent({ section: SECTIONS.SUGGESTER });
    if (handleFocus) {
      handleFocus(true);
    }
  }

  function handleBlur() {
    if (handleFocus) {
      handleFocus(false);
    }
  }

  function handleResetInput() {
    handleReset();
    setSearchTerm('');
    inputElement.current.focus();
    if (handleFocus) {
      handleFocus(true);
    }
  }

  function handleSelectOption(locationTerm) {
    const index = suggestions.findIndex(suggestion => suggestion.term === locationTerm);
    const location = suggestions[index];
    const term = isDetail && location.type === 'hotel' ? location.description : location.term;

    const { text: Text, description: Description, id: ID, type: Type } = location;

    setSearchTerm(term);
    handleSelect(location);
    sendEvent(
      { section: SECTIONS.SUGGESTION },
      { suggestion: { Text, Description, ID, Type }, search: suggesterSearch, index }
    );
    dispatch(resetSuggestionsAction());
    setTimeout(() => {
      inputElement.current?.blur();
    }, 0);
  }

  const showResetBtn = searchTerm.length > 0 && ((fullscreen && isOpen) || !fullscreen);

  return (
    <SuggesterBase onSelect={handleSelectOption}>
      <Input
        onBlur={handleBlur}
        onChange={handleChange}
        onClick={handleClick}
        placeholder={placeholder}
        ref={inputElement}
        value={searchTerm}
      />

      {suggestions.length > 0 && (
        <PopOver portal={!fullscreen}>
          <ComboboxList>
            {suggestions.map(item => (
              <ComboboxOption key={item.id} value={item.term}>
                <SuggesterItem item={item} searchTerm={searchTerm} />
              </ComboboxOption>
            ))}
          </ComboboxList>
        </PopOver>
      )}
      {showResetBtn && (
        <ButtonReset handleClick={handleResetInput} visible={searchTerm.length > 0} />
      )}
    </SuggesterBase>
  );
};

Suggester.propTypes = {
  fullscreen: PropTypes.bool,
  handleFocus: PropTypes.func,
  handleReset: PropTypes.func,
  handleSelect: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  market: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  reset: PropTypes.bool,
  search: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
};

export default Suggester;
