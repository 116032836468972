import styled, { css } from 'styled-components';
import { colors, fonts, updatedMedia } from 'client/styles';
import { FlagBase as Flag } from 'client/components/Flag/styles';
import { THEME_SECONDARY } from './constants';
import IconArrowDown from 'client/icons/ArrowDown';

export const Arrow = styled(IconArrowDown)`
  ${props => props.open && `transform: rotate(180deg)`}
`;

const themeDefault = css`
  border-color: ${colors.grey4};

  ${Arrow} {
    border-top-color: ${colors.grey1};
  }
`;

const themeSecondary = css`
  border-color: ${colors.white};

  ${Arrow} {
    border-top-color: ${colors.white};
  }
`;

export const CountrySelectorBase = styled.div`
  border: 2px solid;
  font-family: ${fonts.default};
  text-rendering: optimizeLegibility;
  ${props => (props.theme === THEME_SECONDARY ? themeSecondary : themeDefault)}
  ${props => (props.isUserNav ? `display: flex;` : `display: inline-block`)};
  background-color: ${colors.white};
  width: 70px;
  height: 40px;
  padding: 6px 16px;
  border-radius: 96px;
  gap: 4px;

  &:hover {
    background-color: var(--color--state--hover-on-light);
  }

  ${updatedMedia.onlyTablet} {
    height: 48px;
    padding: 10px 16px;
  }
`;

export const SelectedItem = styled.div`
  align-items: center;
  border: none;
  cursor: pointer;
  display: flex;
  width: 38px;
  height: 24px;

  ${props => (props.isUserNav ? `width: 100%` : `${Flag}`)};

  &:focus {
    outline: none;
  }

  ${updatedMedia.tablet} {
    height: 24px;
    width: 40px;
  }

  ${updatedMedia.onlyMobile} {
    gap: 0;
  }
`;

const defaultListStyles = css`
  width: 259px;
  top: 45px;
  right: 0;
  box-shadow: var(--elevation-light--2);

  ${updatedMedia.tablet} {
    width: 267px;
  }
`;

const userNavListStyles = css`
  top: 100%;
  left: -1px;
  width: calc(100% + 2px);
  border: 1px solid ${colors.grey4};
`;

export const CountryName = styled.span`
  margin-right: 40px;
  color: ${colors.grey1};
`;

export const List = styled.ul`
  -webkit-overflow-scrolling: touch;
  background-color: ${colors.white};
  padding: 5px 8px;
  position: absolute;
  overflow-y: scroll;
  max-height: 300px;
  z-index: 12;
  border-radius: 8px;
  ${props => (props.isUserNav ? userNavListStyles : defaultListStyles)}

  ${Flag} {
    padding: 5px 8px;

    &:hover {
      background-color: var(--color--state--hover-on-light);
      border-radius: 4px;
      cursor: pointer;
    }
  }

  ${updatedMedia.onlyMobile} {
    ${Flag} {
      padding: 5px 4px;
    }
  }
`;

export const inlineStylesFlags = {
  width: '24px',
  height: '24px',
  fill: colors.grey1,
};
