export const SEND_TRACKING_REQUEST = 'SEND_TRACKING_REQUEST';

export const affiliatesDataFilter = {
  kayak: ['kayakclickid'],
  skyscanner: ['skyscanner_redirectid'],
  tripadvisor: ['fid'],
  hotelscan: ['cid'],
};

export const ORIGIN_DIRECT = 'direct';
