import styled from 'styled-components';
import { colors, updatedMedia } from 'client/styles';

export const LogoLink = styled.a`
  margin-top: 8px;
  height: 32px;
  width: 112.27px;

  svg#arrow-back {
    height: 21.69px;
    width: 111.88px;
    fill: ${props => (props.isEbooking ? colors.white : colors.magenta)};
  }

  ${updatedMedia.onlyTablet} {
    margin-top: 14px;
    width: 168.41px;
    height: 48px;
  }
  ${updatedMedia.onlyMobile} {
    margin-top: 0;
    height: 40px;
    width: 40px;
  }
`;
