import {
  SUGGESTER_STATE,
  FETCH_SUGGESTIONS_FAILURE,
  FETCH_SUGGESTIONS_REQUEST,
  FETCH_SUGGESTIONS_SUCCESS,
  RESET_SUGGESTIONS,
} from './constants';
import stateBase from './state';

const initialState = stateBase[SUGGESTER_STATE];

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SUGGESTIONS_REQUEST:
      return {
        ...state,
        error: null,
        isFetching: true,
        suggestions: [],
        search: action.payload.search,
      };

    case FETCH_SUGGESTIONS_SUCCESS:
      return {
        ...state,
        error: null,
        isFetching: false,
        suggestions: action.payload.suggestions,
      };

    case FETCH_SUGGESTIONS_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        isFetching: false,
        suggestions: [],
      };

    case RESET_SUGGESTIONS:
      return {
        error: null,
        isFetching: false,
        suggestions: [],
        search: '',
      };

    default:
      return state;
  }
};

export default reducer;
