import React from 'react';
import { useSelector } from 'react-redux';

import { getLoginData } from 'client/contexts/Login/selectors';

import CommonHeader, { HeaderProps } from '../CommonHeader';
import CountrySelector from 'client/components/CountrySelector';
import LoginButton from 'src/comps/LoginButton';

import { UserActions } from '../CommonHeader/styles';

import { FULL_WITHOUT_COUNTRY, THEME_DEFAULT } from './constants';
import { AFFILIATE_EBOOKING } from 'src/constants/affiliates';
import { useBusiness } from 'src/hooks/useBusiness';
import { useDevice } from 'src/hooks/useDevice';
import { BreakpointSizes } from 'src/types/device';

const Header: React.FC<HeaderProps> = props => {
  const { hasLogin = true, theme = THEME_DEFAULT, pageId, variant } = props;
  const isErrorPage = typeof pageId === 'number' && pageId >= 400;
  const user = useSelector(getLoginData);
  const { affiliate } = useBusiness();
  const isEbooking = affiliate === AFFILIATE_EBOOKING;
  const { activeBreakpoint } = useDevice();
  const isDesktop = activeBreakpoint >= BreakpointSizes.L;
  const isMobile = activeBreakpoint < BreakpointSizes.M;

  const showCountrySelector = (): boolean => {
    return variant !== FULL_WITHOUT_COUNTRY;
  };

  return (
    <CommonHeader
      {...props}
      isPrime={user?.subscriptionPrime}
      isMobile={isMobile}
      isEbooking={isEbooking}
      userActions={
        <UserActions>
          {hasLogin && <LoginButton theme={theme} isDesktop={isDesktop} isEbooking={isEbooking} />}
          {showCountrySelector() && <CountrySelector pageId={pageId} toHomepage={isErrorPage} />}
        </UserActions>
      }
    />
  );
};

export default Header;
