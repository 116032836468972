import React, { useCallback } from 'react';
import IconChevronLeft from 'client/icons/ChevronLeft';

import { useRouter } from 'next/router';
import IconButon from 'src/comps/Atom/IconButton';

interface IBackButtonProps {
  onClick?: () => void;
  hidden?: boolean;
}

export function BackButton({ onClick, hidden }: Readonly<IBackButtonProps>) {
  const router = useRouter();

  const handleClick = useCallback(() => {
    if (onClick) {
      onClick();
    } else {
      router.back();
    }
  }, [onClick, router]);

  return (
    <IconButon onClick={handleClick} hidden={hidden} dataTestId="arrow-back-button">
      <IconChevronLeft id="chevron-left" data-testid="chevron-left-button" />
    </IconButon>
  );
}
