import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { useDispatch } from 'react-redux';

import { clickLogoHeader } from 'client/contexts/Tracking/events';
import { sendTrackingRequestAction } from 'client/contexts/Tracking/actions';
import { THEME_DEFAULT, THEMES } from '../Header/constants';

import { BackButton } from 'src/comps/Molecules/BackIcon';
import LogoBrand from '../LogoBrand';
import LogoMobile from '../LogoBrand/LogoMobile';

import { LogoLink } from './styles';
import { useBusiness } from 'src/hooks/useBusiness';
import { AFFILIATE_EBOOKING } from 'src/constants/affiliates';
import { EbookingLogo } from '../EbookingLogo';
import { EbookingLink } from '../EbookingLink';
import styles from './styles.module.css';

const HeaderLogo = ({
  backButton = false,
  pageId,
  theme = THEME_DEFAULT,
  isPrime = false,
  isMobile = false,
}) => {
  const dispatch = useDispatch();
  const { affiliate } = useBusiness();
  const isEbooking = affiliate === AFFILIATE_EBOOKING;

  function handleClick() {
    dispatch(sendTrackingRequestAction({ event: clickLogoHeader() }));
  }

  return (
    <div className={styles.leftContainer}>
      <BackButton
        hidden={!backButton}
        onClick={() => window.history.back()}
        data-testid="back-button"
      />
      {isEbooking ? (
        <EbookingLink onClick={handleClick}>
          <EbookingLogo />
        </EbookingLink>
      ) : (
        <Link href="/" passHref>
          <LogoLink data-testid={`logo-link-${pageId}`} onClick={handleClick}>
            {isMobile ? <LogoMobile /> : <LogoBrand theme={theme} isPrime={isPrime} />}
          </LogoLink>
        </Link>
      )}
    </div>
  );
};

HeaderLogo.propTypes = {
  backButton: PropTypes.bool,
  pageId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  theme: PropTypes.oneOf(THEMES),
  isPrime: PropTypes.bool,
  isMobile: PropTypes.bool,
};

export default HeaderLogo;
