// eslint-disable-next-line no-undef
const documentStyle = Object.keys(globalThis?.document?.documentElement?.style ?? {});
const supportsContainerQuery = documentStyle.some(k => k === 'container');
const isTest = process.env.NODE_ENV === 'test';
const isServer = typeof window === 'undefined';

const sholdLoadPolyfill = !supportsContainerQuery && !isTest && !isServer;
if (sholdLoadPolyfill) {
  import('container-query-polyfill');
}
