import styled, { css } from 'styled-components';
import { updatedMedia } from 'client/styles';
import {
  THEMES,
  THEME_EBOOKING,
  THEME_SECONDARY,
  THEME_TERTIARY,
} from 'client/components/Header/constants';

import { AlertMessageBase } from 'src/comps/AlertMessage/styles';
import { NotificationsBase } from 'src/comps/Notifications/styles';

const themePrimary = css`
  background-color: ${props => props.theme.colors.grey8};
  border-bottom: 1px solid ${props => props.theme.colors.grey5};
`;

const themeSecondary = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 11;
`;

const themeTertiary = css`
  width: 100%;
  z-index: 11;
  background-color: ${props => props.theme.colors.grey8};
`;

const themeEbooking = css`
  background-color: var(--color--primary--500);
  color: var(--color--base--white);
`;

export const HeaderBase = styled('header')<{
  headerTheme: (typeof THEMES)[number];
  fixed?: boolean;
  isEbooking?: boolean;
}>`
  display: block;
  position: relative;
  ${({ fixed }) =>
    fixed &&
    css`
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 15;
    `}

  ${props => {
    switch (props.headerTheme) {
      case THEME_SECONDARY:
        return themeSecondary;
      case THEME_TERTIARY:
        return themeTertiary;
      case THEME_EBOOKING:
        return themeEbooking;
      default:
        return themePrimary;
    }
  }}

  ${props => props.isEbooking && themeEbooking}

  ${AlertMessageBase} {
    position: absolute;
    top: 60px;
    left: 0;
    z-index: 100;
  }

  ${NotificationsBase} {
    position: absolute;
    top: calc(100% + 20px);
    right: 20px;
    z-index: 100;
  }
`;

export const HeaderInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin: 0 auto;
  max-width: var(--layout-max-width);
  padding: 8px 16px;

  ${updatedMedia.onlyTablet} {
    padding: 16px 32px;
    height: 80px;
  }

  &.full {
    position: fixed;
    z-index: 11;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.grey8};
  }

  &.borderless {
    max-width: 100%;
  }
`;
