import React, { ReactNode } from 'react';

import {
  THEMES,
  THEME_DEFAULT,
  LINK_ATTRIBUTES,
  VARIANTS,
  FULL,
  LOGO_WITH_HELP,
} from 'client/components/Header/constants';

import { useMarket } from 'src/hooks/useMarket';
import { useDevice } from 'src/hooks/useDevice';
import { LocationPropertyType } from 'src/types/checkout';

import HeaderLogo from 'client/components/HeaderLogo';
import IconHelp from 'client/icons/Help';

import HeaderWithSlots from 'client/components/HeaderWithSlots';

import { RightSideWrapper, Section, SectionLabel, SectionLink, Text } from './styles';
import { BreakpointSizes } from 'src/types/device';
import { FormattedMessage } from 'react-intl';

type QueryParams = { [name: string]: string };
type Theme = (typeof THEMES)[number];
type Variant = (typeof VARIANTS)[number];

export interface HeaderProps {
  backButton?: boolean;
  destinationData?: LocationPropertyType;
  hasLogin?: boolean;
  isLogged?: boolean;
  logoLinkQueryParams?: QueryParams;
  pageId: string | number;
  phone?: string;
  contactEmail?: string;
  theme?: Theme;
  variant?: Variant;
}

interface CommonHeaderProps extends HeaderProps {
  isPrime: boolean;
  isMobile: boolean;
  userActions?: ReactNode;
  isEbooking?: boolean;
}

const CommonHeader: React.FC<CommonHeaderProps> = ({
  backButton = false,
  pageId,
  theme = THEME_DEFAULT,
  variant = FULL,
  isPrime,
  isMobile,
  userActions,
  isEbooking = false,
}) => {
  const { helpCenterUrl } = useMarket();
  const { activeBreakpoint } = useDevice();
  const isDesktop = activeBreakpoint >= BreakpointSizes.L;

  const showUserActions = (): boolean => {
    return variant !== LOGO_WITH_HELP;
  };

  return (
    <HeaderWithSlots
      theme={theme}
      left={
        <HeaderLogo
          backButton={backButton}
          pageId={pageId}
          theme={theme}
          isPrime={isPrime}
          isMobile={isMobile}
        />
      }
      right={
        <RightSideWrapper>
          <Section>
            <SectionLink href={helpCenterUrl} {...LINK_ATTRIBUTES}>
              <SectionLabel isEbooking={isEbooking}>
                <IconHelp />
                {isDesktop && (
                  <Text>
                    <FormattedMessage id="global.help-center" />
                  </Text>
                )}
              </SectionLabel>
            </SectionLink>
          </Section>
          {showUserActions() && userActions && <>{userActions}</>}
        </RightSideWrapper>
      }
    />
  );
};

export default CommonHeader;
