import { addDays, parseISO, isAfter, isSameDay } from 'date-fns';
import omit from 'lodash/omit';
import isEqualWith from 'lodash/isEqualWith';
import { NextRouter } from 'next/router';
import { AccommodationOptions, RatingOptions } from 'src/pages/api/trpc/search/schema';
import {
  BoxShapeType,
  FacilitiesOptions,
  LocationTypes,
  Room,
  SearchActions,
  SearchStatus,
  StarsOptions,
  SuggestionType,
} from 'src/types/search';
import type { ParsedUrlQuery } from 'querystring';
import { GeoPathParams, PathParams, SerpPathParams } from 'src/types/results';
import isNumber from 'lodash/isNumber';

type UniversalDate = {
  date: number;
  month: number;
  year: number;
  hours: number;
  minutes: number;
};

export const getMinBookingDate = (localDate: UniversalDate, utcDate: UniversalDate) => {
  const localTimeInMillis = new Date(
    localDate.year,
    localDate.month,
    localDate.date,
    localDate.hours,
    localDate.minutes
  ).getTime();

  const utcTimeInMillis = new Date(
    utcDate.year,
    utcDate.month,
    utcDate.date,
    utcDate.hours,
    utcDate.minutes
  ).getTime();

  const TWENTY_FOUR_HOURS_IN_MILLIS = 24 * 60 * 60 * 1000;

  // Allow only local dates within a range of 24 hours before or after the UTC date.
  if (
    localTimeInMillis >= utcTimeInMillis - TWENTY_FOUR_HOURS_IN_MILLIS &&
    localTimeInMillis <= utcTimeInMillis + TWENTY_FOUR_HOURS_IN_MILLIS
  ) {
    return localDate;
  }

  return utcDate;
};

interface IDateRange {
  checkIn?: string | string[];
  checkOut?: string | string[];
  minBookingDate: Date;
  daysOfBooking: number;
}

export const getDateRange = ({ checkIn, checkOut, minBookingDate, daysOfBooking }: IDateRange) => {
  const parsedDate = parseISO(checkIn as string);
  const checkinIsAfterMinBookingDate =
    checkIn && (isAfter(parsedDate, minBookingDate) || isSameDay(parsedDate, minBookingDate));

  let checkin = minBookingDate;
  if (checkinIsAfterMinBookingDate && checkIn) {
    checkin = new Date(checkIn as string);
  }

  let checkout = addDays(checkin, daysOfBooking);
  if (checkinIsAfterMinBookingDate && checkOut) {
    checkout = new Date(checkOut as string);
  }

  return {
    checkin,
    checkout,
  };
};

export function getRoomsFromUrlParams({
  adults,
  children = '',
}: {
  adults: string | string[];
  children?: string | string[];
}) {
  const adultsArray = (Array.isArray(adults) ? adults : adults.split(',')).map(a =>
    parseInt(a, 10)
  );

  const childrenArray = (Array.isArray(children) ? children : children.split('!'))
    .map(i => i.split(',').map(j => (j ? parseInt(j, 10) : null)))
    .map(k => (k[0] === null || Number.isNaN(k[0]) ? [] : k));

  return adultsArray.map((adultNumber, index) => ({
    adults: adultNumber,
    children: childrenArray[index] ?? [],
  })) as Room[];
}

export function getRoomsFromLocalParams(adults: number[], children: number[][]) {
  return adults.map((_, i) => ({ adults: adults[i], children: children[i] ?? [] }));
}

export const omitQueryParams = async (router: NextRouter, params: string[]) => {
  const { query } = router;
  return router.replace(
    {
      query: omit(query, params),
    },
    undefined,
    { shallow: true }
  );
};

export function updateSearchStatus(searchStatus: SearchStatus, searchAction: SearchActions) {
  if (searchStatus === SearchStatus.Idle && searchAction === SearchActions.Fetch) {
    return SearchStatus.Fetching;
  }

  if (searchStatus === SearchStatus.Fetching && searchAction === SearchActions.Cancel) {
    return SearchStatus.Idle;
  }

  if (searchStatus === SearchStatus.Fetching && searchAction === SearchActions.Error) {
    return SearchStatus.Error;
  }

  if (searchStatus === SearchStatus.Fetching && searchAction === SearchActions.Success) {
    return SearchStatus.Success;
  }

  return SearchStatus.Idle;
}

export const getRating = (ratings: Set<RatingOptions>) => {
  const ratingList = Array.from(ratings);

  if (ratingList.length === 0) {
    return undefined;
  }

  // ratings is a Set but we only support one rating for now
  return ratingList[0];
};

export const getStars = (stars: Set<StarsOptions>) => {
  return Array.from(stars).map(star => parseInt(star, 10));
};

export const getAccomodations = (accommodations: Set<AccommodationOptions>) => {
  return Array.from(accommodations);
};

export const getFacilities = (facilities: Set<FacilitiesOptions>) => {
  return Array.from(facilities);
};

export const replaceQueryParams = async (
  router: NextRouter,
  params: ParsedUrlQuery,
  currentParams?: ParsedUrlQuery | undefined
) => {
  const { query } = router;
  return router.replace(
    {
      query: {
        ...(currentParams ?? query),
        ...params,
      },
    },
    undefined,
    { shallow: true }
  );
};

export const isProperty = (locationType?: string) => {
  return (
    locationType !== undefined &&
    locationType !== LocationTypes.City &&
    locationType !== LocationTypes.Country &&
    locationType !== LocationTypes.Neighborhood &&
    locationType !== LocationTypes.POI &&
    locationType !== LocationTypes.Region &&
    locationType !== LocationTypes.Zone &&
    locationType !== LocationTypes.Geo
  );
};

export const isGeo = (locationType: string) => {
  return locationType === LocationTypes.Geo;
};

export function setsAreEqual(setA: Set<unknown>, setB: Set<unknown>): boolean {
  return isEqualWith(setA, setB, (a: unknown, b: unknown) => {
    if (a === setA) return undefined;
    return a === b;
  });
}

export const emptyLocation: SuggestionType = {
  ID: '0',
  Coordinate: { lat: 0, lon: 0 },
  Country: '',
  Slug: '',
  Type: '',
  Text: '',
  Description: '',
  Stars: 0,
  CitySlug: '',
};

export const geoLocation: SuggestionType = {
  ...emptyLocation,
  Type: 'geo',
  Text: 'Map area',
  Description: 'Map area',
  BoxShape: {
    topLeft: {
      lat: 0,
      lon: 0,
    },
    bottomRight: {
      lat: 0,
      lon: 0,
    },
  },
};

export const getBoxShapeFromBounds = (bounds: L.LatLngBounds): BoxShapeType => {
  const northWest = bounds.getNorthWest();
  const southEast = bounds.getSouthEast();

  return {
    topLeft: {
      lat: northWest.lat,
      lon: northWest.lng,
    },
    bottomRight: {
      lat: southEast.lat,
      lon: southEast.lng,
    },
  };
};

export const boxShapeToArray = (boxShape: BoxShapeType): Array<number> => {
  return [
    boxShape.topLeft.lat,
    boxShape.topLeft.lon,
    boxShape.bottomRight.lat,
    boxShape.bottomRight.lon,
  ];
};

export const getCenterFromBoxShape = (boxShape: BoxShapeType) => {
  let bottomRightLon = boxShape.bottomRight.lon;
  let topLeftLon = boxShape.topLeft.lon;

  if (
    boxShape.bottomRight.lon - boxShape.topLeft.lon > 180 ||
    boxShape.topLeft.lon - boxShape.bottomRight.lon > 180
  ) {
    bottomRightLon += 360;
    bottomRightLon %= 360;
    topLeftLon += 360;
    topLeftLon %= 360;
  }

  const centerLat = (boxShape.bottomRight.lat + boxShape.topLeft.lat) / 2;
  const centerLng = (bottomRightLon + topLeftLon) / 2;
  return { lat: centerLat, lon: centerLng };
};

export const queryParamToNumber = (param: string | number): number => {
  return isNumber(param) ? param : parseFloat(param);
};

export const getLocationFromGeoParams = (params: GeoPathParams) => {
  return {
    ...geoLocation,
    BoxShape: {
      topLeft: {
        lat: queryParamToNumber(params?.boxshape?.[0] ?? 0),
        lon: queryParamToNumber(params?.boxshape?.[1] ?? 0),
      },
      bottomRight: {
        lat: queryParamToNumber(params?.boxshape?.[2] ?? 0),
        lon: queryParamToNumber(params?.boxshape?.[3] ?? 0),
      },
    },
  };
};

export const getLocationFromUrlParams = (params: PathParams) => {
  const type = params?.type;
  if (!type) return null;

  if (type === 'geo') {
    return getLocationFromGeoParams(params as GeoPathParams);
  }

  const { country = '', slug = '' } = params as SerpPathParams;

  return {
    ...emptyLocation,
    Type: params.type ?? '',
    Country: country,
    Slug: slug,
  };
};

export const getUniqueKeyFromLocation = (location: SuggestionType) => {
  return `${location.Country}-${location.Type}-${location.Slug}`;
};

export const fetchLocationFromGeoParams = (params: GeoPathParams) => {
  const STATUS_CODE_OK = 200;
  const STATUS_CODE_NOT_FOUND = 404;

  const data = getLocationFromGeoParams(params);
  const status = params?.boxshape?.length === 4 ? STATUS_CODE_OK : STATUS_CODE_NOT_FOUND;
  return { status, data };
};

type Search = {
  location?: string;
  checkin?: number;
  checkout?: number;
  rooms?: Room[];
};

export const hasChangeSearch = (search1: Search, search2: Search) => {
  if (search1?.location !== search2?.location) {
    return true;
  }
  if (search1?.checkin !== search2?.checkin) {
    return true;
  }
  if (search1?.checkout !== search2?.checkout) {
    return true;
  }

  if (search1?.rooms?.length !== search2?.rooms?.length) {
    return true;
  }

  const adults1 = search1?.rooms?.reduce((acc, room) => acc + room.adults, 0);
  const adults2 = search2?.rooms?.reduce((acc, room) => acc + room.adults, 0);
  if (adults1 !== adults2) {
    return true;
  }

  const children1 = search1?.rooms?.reduce((acc, room) => acc + room.children.length, 0);
  const children2 = search2?.rooms?.reduce((acc, room) => acc + room.children.length, 0);
  if (children1 !== children2) {
    return true;
  }

  return false;
};
