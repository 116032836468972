export const THEME_PRIMARY = 'primary';
export const THEME_SECONDARY = 'secondary';
export const THEME_TERTIARY = 'tertiary';
export const THEME_EBOOKING = 'ebooking';

export const THEME_DEFAULT = THEME_PRIMARY;
export const THEMES = [THEME_PRIMARY, THEME_SECONDARY, THEME_TERTIARY, THEME_EBOOKING];

export const LINK_ATTRIBUTES = {
  target: '_blank',
  rel: 'nofollow, noopener',
};

export const VARIANTS = ['full', 'logoWithHelp', 'fullWithoutCountry'];
export const FULL = 'full';
export const LOGO_WITH_HELP = 'logoWithHelp';
export const FULL_WITHOUT_COUNTRY = 'fullWithoutCountry';
