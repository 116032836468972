import { camelCaseKeys } from 'src/pages/api/trpc/helper';

const MISSING_TRANSLATION = 'MISSING TRANSLATION';
export const ROOM_FACILITIES_SOURCE = {
  ROOM_NAME: 'room_by_name',
  ROOM_CODE: 'room_by_code',
  ROOM_MISSING_TRANSLATION: 'room_by_missing_translation',
  DEFAULT: 'defaults',
};

export function buildAvailabilityRequest({
  adults,
  checkIn,
  checkOut,
  children,
  language,
  market,
  device,
  affiliate,
  roomMapping,
  membership,
  source,
  minSellingRate,
  currency,
  timeout,
}) {
  return {
    adultsByRoom: adults.join(),
    checkin: checkIn,
    checkout: checkOut,
    childrenAges: children.flat().join(),
    childrenByRoom: children.map(c => c.length).join(),
    lang: language,
    language,
    market,
    currency,
    device,
    affiliate,
    ...(membership && { membership: true }),
    ...(roomMapping && { roomMapping: true }),
    ...(source && { source }),
    ...(minSellingRate && { minSellingRate }),
    timeout,
  };
}

export function mapPaxes({ adults, children, rooms, children_ages: childrenAges }) {
  return {
    adults,
    children,
    childrenAges,
    paxesId: `${rooms}-${adults}-${children}-${childrenAges || 0}`,
    roomsNumber: rooms,
  };
}

export function mapRoom(room, index) {
  return {
    roomName: room.room_name.toLowerCase(),
    roomUniqueId: room.room_unique_id || index + 1,
    roomIndex: index + 1,
  };
}

export function mapAdditionalFeesToArray(data) {
  if (!data || data.length === 0) {
    return [];
  }

  return [data];
}

function mapSummary(board) {
  const {
    additional_fees: additionalFees,
    bighead_rate: bighead,
    subtotal,
    taxes,
    total,
    subtotal_nightly: subtotalNightly,
    total_with_fees: totalWithFees,
    total_nightly_with_fees: totalNightlyWithFees,
  } = board.summary || {};

  const totalAmount = total?.amount || board.selling_rate || null;

  return {
    additionalFees: mapAdditionalFeesToArray(additionalFees) || null,
    bighead: bighead?.amount || board.bighead_rate || null,
    bigheadCurrency: bighead?.currency || board.currency || null,
    pricePerNight: board.avg_rate || null,
    pricePerNightCurrency: total?.currency || board.currency || null,
    subtotal: subtotal?.amount || null,
    subtotalCurrency: subtotal?.currency || null,
    pricePerNightSubtotal: subtotalNightly?.amount || null,
    pricePerNightSubtotalCurrency: subtotalNightly?.currency || null,
    taxes: taxes?.amount || null,
    taxesCurrency: board.currency || null,
    total: totalAmount,
    totalCurrency: total?.currency || board.currency || null,
    totalWithFees: totalWithFees?.amount ?? null,
    totalWithFeesCurrency: totalWithFees?.currency ?? null,
    pricePerNightWithFees: totalNightlyWithFees?.amount ?? null,
    pricePerNightWithFeesCurrency: totalNightlyWithFees?.currency ?? null,
  };
}

export function mapPhoto(photo) {
  const { id, width, height, availablePaths, roomCode } = camelCaseKeys(photo);
  return {
    id,
    width,
    height,
    availablePaths,
    roomCode,
  };
}

export function getPhotosByCode(photos = [], code) {
  const lowerCaseCode = code.toLowerCase();
  return photos
    .filter(photo => photo.room_code?.toLowerCase() === lowerCaseCode)
    .map(photo => mapPhoto(photo));
}

export function getPhotosByRoom(roomContent, { name, code }) {
  if (roomContent?.[name]?.images?.length) {
    return roomContent[name].images.map(photo => mapPhoto(photo));
  }

  const filteredRoomsWithImages = Object.keys(roomContent).filter(roomName => {
    const room = roomContent[roomName];
    const roomCodes = room?.images_room_codes ?? [];
    const hasImages = room?.images?.length > 0;
    const lowerCaseCode = code.toLowerCase();
    const isCodeInRoomCodes = roomCodes.some(roomCode => roomCode.toLowerCase() === lowerCaseCode);
    return isCodeInRoomCodes && hasImages;
  });
  if (filteredRoomsWithImages.length) {
    const roomName = filteredRoomsWithImages?.[0];
    return roomContent[roomName].images.map(photo => mapPhoto(photo));
  }

  const photos = roomContent?.[MISSING_TRANSLATION]?.images;
  return getPhotosByCode(photos, code);
}

export function mapFacility(facility) {
  const { codeGroup, slug, alias, free, featured, name, order, icon, id } = camelCaseKeys(facility);
  return {
    codeGroup,
    slug,
    alias,
    icon:
      (icon && /base64-icon/.test(icon) && icon.replace('base64-icon ', '')) ||
      alias ||
      id ||
      'done',
    free,
    featured,
    name,
    order,
  };
}

function getRoomPhotos(photos, roomInfo, roomContent) {
  if (roomContent) {
    return getPhotosByRoom(roomContent, roomInfo);
  }

  return getPhotosByCode(photos, roomInfo.code);
}

export function buildRoomFacilitiesObject(facilities, source) {
  return {
    facilities,
    source,
  };
}

export function getFacilitiesByCode(code, facilities = []) {
  return facilities
    .filter(facility => facility.room_code.toLowerCase() === code.toLowerCase())
    .map(facility => mapFacility(facility));
}

export function getFacilitiesByRoom(roomContent, { name, code }) {
  if (!roomContent) {
    return buildRoomFacilitiesObject([], '');
  }

  if (roomContent?.[name]?.facilities?.length) {
    return buildRoomFacilitiesObject(
      roomContent[name].facilities.map(facility => mapFacility(facility)),
      ROOM_FACILITIES_SOURCE.ROOM_NAME
    );
  }

  const filteredRoomsWithFacilities = Object.keys(roomContent).filter(roomName => {
    const room = roomContent[roomName];
    const roomCodes = room?.facilities_room_codes ?? [];
    const hasFacilities = room?.facilities?.length > 0;
    const lowerCaseCode = code.toLowerCase();
    const isCodeInRoomCodes = roomCodes.some(roomCode => roomCode.toLowerCase() === lowerCaseCode);
    return isCodeInRoomCodes && hasFacilities;
  });
  if (filteredRoomsWithFacilities.length) {
    const roomName = filteredRoomsWithFacilities?.[0];
    return buildRoomFacilitiesObject(
      roomContent[roomName].facilities.map(facility => mapFacility(facility)),
      ROOM_FACILITIES_SOURCE.ROOM_CODE
    );
  }

  const facilities = roomContent?.[MISSING_TRANSLATION]?.facilities;
  const facilitiesByCode = getFacilitiesByCode(code, facilities);
  if (facilitiesByCode.length) {
    return buildRoomFacilitiesObject(
      facilitiesByCode,
      ROOM_FACILITIES_SOURCE.ROOM_MISSING_TRANSLATION
    );
  }

  return buildRoomFacilitiesObject([], '');
}

export function getRoomFacilities(roomInfo, data) {
  const roomFacilities = getFacilitiesByRoom(data.room_content, roomInfo);
  if (roomFacilities.facilities.length) {
    return roomFacilities;
  }

  if (data?.defaults?.room_facilities?.length) {
    return buildRoomFacilitiesObject(
      data?.defaults?.room_facilities?.map(facility => mapFacility(facility)),
      ROOM_FACILITIES_SOURCE.DEFAULT
    );
  }

  return buildRoomFacilitiesObject([], '');
}

function getBasicBoardInfo(board, index) {
  return {
    adults: board.adults,
    allotment: board.allotment,
    availabilityId: board.id_availability,
    beds: board.beds,
    boardIndex: index + 1,
    boardName: board.board_name.toLowerCase(),
    cancellation: board.cancellation_policies,
    cancellationFrom: board.cancellation_policies[0]?.from || '',
    checkIn: board.checkin,
    checkOut: board.checkout,
    children: board.children,
    childrenAges: board.children_ages || '',
    code: board.board_code,
  };
}

function getRoomInfo(board, index) {
  return {
    roomCode: board.room_code.toLowerCase(),
    normalizedRoomCode: board.normalized_room_code?.toLowerCase() || null,
    roomGroup: board.group || null,
    roomIndex: index + 1,
    roomName: board.room_name.toLowerCase(),
    roomsNumber: board.rooms,
    roomUniqueId: board.room_unique_id || index + 1,
  };
}

function getPricingInfo(board) {
  return {
    currency: board.currency,
    discount: board.discount || null,
    finalPrice: board.selling_rate,
    initialPrice: board.bighead_rate,
    payRate: board.pay_rate,
    sellingRate: board.selling_rate || null,
    sellingRateCurrency: board.currency || null,
  };
}

function getAdditionalInfo(board) {
  return {
    credentialSource: board.credential_source || null,
    isBestPrice: board.is_best_price || false,
    isMandatory: board.hotel_mandatory,
    isRecheck: board.rate_type === 'RECHECK',
    isPrime: board.rate_discounts?.prime || false,
    isMobile: board.rate_discounts?.mobile || false,
    isPhysicalAddressRequired: board.physical_address_required || false,
    paxesId: `${board.rooms}-${board.adults}-${board.children}-${board.children_ages || 0}`,
    paymentPolicy: board.rate_class || board.payment_policy,
    provider: board.provider || null,
    sflPromotions: board.sfl_promotions || null,
    stayWalletCredit: board.wallet_credit || null,
    supplierTerms: board.terms_and_conditions || null,
    catalogue: board.catalogue || null,
  };
}

export function mapBoard({ board, index, photos = [], data = false }) {
  const roomContent = data?.room_content;
  const roomInfo = { name: board?.room_name, code: board?.room_code };

  const roomPhotos = getRoomPhotos(photos, roomInfo, roomContent);
  const roomFacilities = getRoomFacilities(roomInfo, data);

  return {
    ...mapSummary(board),
    ...getBasicBoardInfo(board, index),
    ...getRoomInfo(board, index),
    ...getPricingInfo(board),
    ...getAdditionalInfo(board),
    facilities: roomFacilities.facilities,
    roomFacilitiesSource: roomFacilities.source,
    photos: roomPhotos,
  };
}

function mapAdditionalProducts(data) {
  const insurance =
    data.additional_products &&
    data.additional_products.find(product => product.type === 'insurance');

  if (!insurance) {
    return null;
  }

  return {
    insuranceSelected: false,
    insurance: {
      amount: insurance.amount,
      currency: insurance.currency,
      description: insurance.description,
      type: insurance.type,
      terms: insurance.terms,
    },
  };
}

function mapBestPriceData(meta = {}, boards = []) {
  let additionalFees = 0;
  let totalWithFees = 0;
  let pricePerNightWithFees = 0;
  let subtotal = 0;
  let pricePerNightSubtotal = 0;

  boards.forEach(board => {
    if (board.isBestPrice) {
      additionalFees += board?.additionalFees ?? 0;
      totalWithFees += board?.totalWithFees ?? 0;
      pricePerNightWithFees += board?.pricePerNightWithFees ?? 0;
      subtotal += board?.subtotal ?? 0;
      pricePerNightSubtotal += board?.pricePerNightSubtotal ?? 0;
    }
  });

  return {
    bestPriceData: {
      isPrime: meta.is_prime || false,
      isMobile: boards[0]?.isMobile || false,
      perNight: meta.total_avg_rate,
      roomsCount: meta.total_num_rooms,
      subtotal: subtotal || null,
      pricePerNightSubtotal: pricePerNightSubtotal || null,
      totalBeforeDiscount: meta.total_bighead_rate,
      totalDiscounted: meta.total_selling_rate,
      ...(additionalFees && { totalWithFees, pricePerNightWithFees }),
    },
    isMultiroom: boards?.length > 1,
    roomsRequested: boards?.length,
  };
}

export function mapAvailabilityResponse(data) {
  const [propertyId] = Object.keys(data);
  const { availability: boards, best_price_totals: meta } = data[propertyId];
  const mappedBoards =
    boards?.map((board, index) => mapBoard({ board, index, photos: data?.room_images, data })) ||
    [];

  return {
    meta: mapBestPriceData(meta, mappedBoards),
    boards: mappedBoards,
  };
}

export function mapBookingRulesResponse(data) {
  if (!data || data.length === 0) {
    return null;
  }

  return {
    ...mapAdditionalProducts(data),
    cancellation: data.cancellation_policies,
    finalPrice: data.selling_rate,
    initialPrice: data.bighead_rate,
    paymentPolicy: data.rate_class || data.payment_policy,
    payRate: data.pay_rate,
    stayWalletCredit: data.wallet_credit,
    pricePerNight: data.avg_rate,
  };
}

export function updateBookingRulesWithFees(board, bookingRules) {
  let { totalWithFees, pricePerNightWithFees } = board;

  if (board.finalPrice !== bookingRules.finalPrice) {
    const nights = Math.round(board.finalPrice / board.pricePerNight);
    const finalPriceDiff = bookingRules.finalPrice - board.finalPrice;
    totalWithFees += finalPriceDiff;
    pricePerNightWithFees = totalWithFees / nights;
  }

  return {
    ...board,
    ...bookingRules,
    ...(!Number.isNaN(totalWithFees) && { totalWithFees }),
    ...(!Number.isNaN(pricePerNightWithFees) && { pricePerNightWithFees }),
  };
}
