import { AVAILABILITY_STATE } from './constants';

const state = {
  [AVAILABILITY_STATE]: {
    error: null,
    isFetching: true,
    meta: {},
    boards: [],
    rulesComplete: 0,
    isBookingRulesLoading: {},
  },
};

export default state;
