import styled, { css } from 'styled-components';
import { colors, media, updatedMedia } from 'client/styles';

import { LoginNavigatorBase } from 'src/comps/LoginNavigator/styles';
import { SidebarMenuBase } from 'src/comps/SidebarMenu/styles';
import { LoginButtonBase } from 'src/comps/LoginButton/styles';
import { CountrySelectorBase } from 'client/components/CountrySelector/styles';

export const UserActions = styled.div`
  align-items: center;
  display: flex;
  flex: 1 0 auto;
  justify-content: flex-end;
  position: relative;
  gap: 8px;

  ${media.tablet} {
    ${LoginNavigatorBase}, ${SidebarMenuBase} {
      position: absolute;
      top: 38px;
      z-index: 20;
      background-color: ${colors.white};
    }

    ${LoginNavigatorBase} {
      left: calc(100% - 170px);
      width: 200px;
      box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.125);
    }

    ${SidebarMenuBase} {
      left: calc(100% - 345px);
      width: 350px;
      border: 2px solid ${colors.grey4};
      border-radius: 8px;
    }
  }
  ${updatedMedia.tablet} {
    gap: 16px;
  }
`;

export const RightSideWrapper = styled.div<{ isSearch?: boolean }>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  ${({ isSearch }) =>
    isSearch &&
    css`
      ${LoginButtonBase} {
        margin-right: 0;
      }
      ${updatedMedia.tablet} {
        ${CountrySelectorBase} {
          margin-right: 16px;
        }
      }
    `}
  ${updatedMedia.tablet} {
    gap: 16px;
  }

  ${updatedMedia.desktop} {
    height: 40px;
  }
`;

export const Section = styled.div`
  display: flex;
  align-items: center;
`;

export const SectionLabel = styled.div<{ isEbooking?: boolean }>`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color--neutral--300);
  border-radius: 96px;
  border: none;

  &:hover {
    background-color: var(--color--state--hover-on-primary);
  }

  color: ${props =>
    props.isEbooking ? 'var(--color--base--white)' : 'var(--color--neutral--800)'};

  svg {
    width: 24px;
    height: 24px;
    fill: ${props =>
      props.isEbooking ? 'var(--color--base--white)' : 'var(--color--neutral--800)'};
  }

  ${updatedMedia.onlyTablet} {
    width: 48px;
    height: 48px;

    svg {
      width: 24px;
      height: 24px;
      fill: ${props =>
        props.isEbooking ? 'var(--color--base--white)' : 'var(--color--neutral--800)'};
    }
  }
  ${updatedMedia.desktop} {
    width: auto;
    height: 40px;
    gap: 10px;
    padding: 0 12px;

    svg {
      width: 16px;
      height: 16px;
    }
  }
`;

export const CallUsLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  color: inherit;
`;

export const SectionLink = styled.a`
  cursor: pointer;
  text-decoration: none;
`;

export const Text = styled.div`
  height: 21px;
  font-size: 14px;
  line-height: 21px;
  font-weight: 700;
`;
