import { requestByGET, requestByPOST } from 'lib/requests';
import { buildPathParams } from 'lib/utils/url';
import { buildSearchPropertiesPayload } from 'src/effects/Search/mappers';
import { mapProperty, mapProperties } from './mappers';

const OPTIONS = {
  headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
  timeout: 30000,
  withCredentials: true,
};

const END_POINTS = {
  PROPERTIES_BY_ID: '/properties/ids/full',
  PROPERTIES_BY_SEARCH_AUTH: '/v2/search/member',
  PROPERTIES_BY_SEARCH: '/v2/search',
  PROPERTY_BY_SLUG: '/property/{country}/{city}/{slug}',
};

export function fetchProperties(params) {
  return requestByPOST({
    data: buildSearchPropertiesPayload(params),
    endpoint: END_POINTS.PROPERTIES_BY_SEARCH,
  });
}

export function fetchPropertiesAuthorized(params) {
  return requestByPOST({
    data: buildSearchPropertiesPayload({ ...params, source: 'web' }),
    endpoint: END_POINTS.PROPERTIES_BY_SEARCH_AUTH,
    options: OPTIONS,
  });
}

export function fetchPropertiesById({ language, ids }) {
  return requestByGET({
    data: { lang: language, id_properties: ids.join() },
    endpoint: END_POINTS.PROPERTIES_BY_ID,
    mapper: mapProperties,
  });
}

export function fetchPropertyBySlug({ city, country, slug, language }) {
  return requestByGET({
    data: { lang: language },
    endpoint: buildPathParams(END_POINTS.PROPERTY_BY_SLUG, { city, country, slug }),
    mapper: mapProperty,
  });
}
