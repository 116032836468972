import { SUGGESTER_STATE } from './constants';

const state = {
  [SUGGESTER_STATE]: {
    error: null,
    isFetching: false,
    search: '',
    suggestions: [],
  },
};

export default state;
