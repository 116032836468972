import React, { ReactNode } from 'react';
import styles from './styles.module.css';
import { THEMES } from 'client/components/Header/constants';
import cn from 'classnames';

interface IProps {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  children: ReactNode;
  className?: string;
  dataTestId?: string;
  hidden?: boolean;
  small?: boolean;
  largeExtraWidth?: boolean;
}

export interface ButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  theme?: (typeof THEMES)[number];
}

const IconButon: React.FC<IProps & ButtonProps> = ({
  onClick,
  children,
  dataTestId,
  hidden,
  small,
  largeExtraWidth,
  ...props
}) => {
  const combinedClassName = cn(styles.iconButton, {
    [styles.smallButton]: small,
    [styles.extraWidthButton]: largeExtraWidth,
    [styles.hidden]: hidden,
  });

  return (
    <button
      type="button"
      className={combinedClassName}
      onClick={onClick}
      data-testid={dataTestId}
      {...props}
    >
      {children}
    </button>
  );
};

export default IconButon;
