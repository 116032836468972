import { requestByPOST } from 'lib/requests';
import { buildSearchMapPropertiesPayload } from './mappers';

const OPTIONS = {
  headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
  timeout: 30000,
  withCredentials: true,
};

const END_POINTS = {
  SEARCH_MAP: '/v2/search-by-coordinates',
  SEARCH_MAP_AUTH: '/v2/search-by-coordinates/member',
};

export function fetchMapProperties(params) {
  return requestByPOST({
    data: buildSearchMapPropertiesPayload(params),
    endpoint: END_POINTS.SEARCH_MAP,
  });
}

export function fetchMapPropertiesAuthorized(params) {
  return requestByPOST({
    data: buildSearchMapPropertiesPayload({ ...params, source: 'web' }),
    endpoint: END_POINTS.SEARCH_MAP_AUTH,
    options: OPTIONS,
  });
}
