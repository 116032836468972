import styled, { css, keyframes } from 'styled-components';
import { colors, updatedMedia } from 'client/styles';
import { ButtonIcon } from 'src/comps/Button/styles';
import {
  VARIANT_ALONE,
  VARIANT_CUSTOM,
  VARIANT_DEFAULT,
  VARIANT_EXTENDED,
  VARIANT_FULLSCREEN,
  VARIANT_FULLSCREEN_PARTIAL,
  VARIANT_MID_SCREEN,
  VARIANT_NEW_MODAL,
} from './constants';

const slideUp = keyframes`
  0% { transform: translateY(100%) }
  100% { transform: translateY(0) }
`;

export const ModalBase = styled.div`
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 30;
  height: 100%;
  cursor: default;

  ${props => props.displayHeaderPage && `margin-top: 70px;`}

  ${updatedMedia.onlyMobile} {
    ${props => props.displayHeaderPage && `margin-top: 56px;`}
  }

  ${props =>
    props.variant === VARIANT_NEW_MODAL &&
    css`
      background-color: var(--color--state--backdrop);
      padding: 0;
    `}
`;

const defaultStyle = css`
  border-radius: 8px;
  box-shadow: 8px 1px 40px rgba(0, 0, 0, 0.45);
  margin: 5% auto;
  max-height: 90%;
  max-width: ${props => (props.width ? props.width : '380px')};
  width: 90%;
`;

const midScreenStyle = css`
  border-radius: 8px;
  box-shadow: 8px 1px 40px rgba(0, 0, 0, 0.45);
  margin: 5% auto;
  max-height: 90%;
  max-width: 580px;
  width: 90%;
`;

const extendedStyle = css`
  border-radius: 8px;
  box-shadow: 8px 1px 40px rgba(0, 0, 0, 0.45);
  margin: 0 auto;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  overflow: hidden;
`;

const fullScreenStyle = css`
  max-width: initial;
  width: 100%;
  height: 100%;
`;

const fullScreenPartialStyle = css`
  max-width: initial;
  width: 100%;
`;

const customStyle = css`
  max-width: initial;
  width: auto;
  margin: 0 auto;
  border-radius: 8px;
`;

const newModalStyle = css`
  max-width: 542px;
  width: 90%;
  margin: 0 auto;
  border-radius: var(--radius-2);
`;

const fixedTitle = css`
  width: 100%;
  z-index: 10;
  position: fixed;
`;

const getModalPosition = position => {
  if (position === 'top') {
    return 'flex-start';
  }

  if (position === 'bottom') {
    return 'flex-end';
  }

  return position;
};

export const Content = styled.div`
  align-self: ${props => getModalPosition(props.position)};
  background-color: ${colors.white};
  overflow: auto;
  position: relative;

  ${props => props.variant === VARIANT_ALONE && defaultStyle};
  ${props => props.variant === VARIANT_DEFAULT && defaultStyle};
  ${props => props.variant === VARIANT_EXTENDED && extendedStyle};
  ${props => props.variant === VARIANT_FULLSCREEN && fullScreenStyle};
  ${props => props.variant === VARIANT_FULLSCREEN_PARTIAL && fullScreenPartialStyle};
  ${props => props.variant === VARIANT_CUSTOM && customStyle};
  ${props => props.variant === VARIANT_MID_SCREEN && midScreenStyle};
  ${props => props.variant === VARIANT_NEW_MODAL && newModalStyle};
  ${props =>
    props.animate === 'slideUp' &&
    css`
      animation: ${slideUp} 0.25s ease-out;
    `};
`;

export const CloseButtonWrapper = styled.div`
  position: absolute;
  z-index: 1;
  top: 15px;
  right: 15px;
  svg {
    fill: ${colors.grey1};
  }

  & button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const headerWithTitle = css`
  min-height: 60px;
  ${props => (props.fixedTitle ? fixedTitle : '')};
`;

const emptyHeader = css`
  justify-content: flex-end;
  button {
    padding: 20px;

    svg {
      width: 24px;
      height: 24px;
    }
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  top: 0;
  position: sticky;
  z-index: 1;
  background-color: ${colors.white};

  ${props => (props.variant !== VARIANT_CUSTOM ? headerWithTitle : emptyHeader)}
`;

export const Title = styled.h4`
  --padding-left: 20px;
  color: ${colors.grey1};
  flex: 1 0 auto;
  font-size: 1.1em;
  font-weight: 700;
  padding-left: ${props => (props.fixedTitle ? '0' : '1em')};
  vertical-align: middle;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: min(calc(100% - 56px), 1280px);
  margin: 0 auto;
  padding-left: var(--padding-left);
`;

export const ButtonClose = styled(ButtonIcon)`
  padding: 1em;
`;

export const ContentFallback = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ExternalContent = styled.div`
  height: ${props => (props.displayHeader ? 'calc(100% - 60px)' : '100%')};
  background-color: ${colors.white};
`;
