import {
  PAYMENT_METHOD_MASTERCARD_ID,
  PAYMENT_METHOD_VISA_ID,
} from 'src/comps/PaymentForm/constants';

export const MEASUREMENT_UNITS = {
  METRIC: 'metric',
  IMPERIAL: 'imperial',
};

export const COUNTRIES = {
  gb: {
    countryCode: 'gb',
    countryLanguage: 'English',
    countryName: 'United Kingdom',
    contactEmail: 'emily@cs.stayforlong.com',
    currency: 'GBP',
    language: 'en',
    locale: 'en-GB',
    market: 'uk',
    phone: '+441173702990',
    measurementSystem: MEASUREMENT_UNITS.METRIC,
    topdomain: 'co.uk',
    subdomain: null,
    helpCenterUrl: 'https://stayforlong.elevio.help/en',
    faqsUrl: 'https://stayforlong.elevio.help/en/categories/7-faq-s',
    daysOfBooking: 1,
    defaultBookingDays: 3,
    inanitionDays: 0,
    chatbot: false,
  },
  fr: {
    countryCode: 'fr',
    countryLanguage: 'Français',
    countryName: 'France',
    contactEmail: 'gabrielle@cs.stayforlong.com',
    currency: 'EUR',
    language: 'fr',
    locale: 'fr-FR',
    market: 'fr',
    phone: '+33428770117',
    measurementSystem: MEASUREMENT_UNITS.METRIC,
    topdomain: 'fr',
    subdomain: null,
    helpCenterUrl: 'https://stayforlong.elevio.help/fr',
    faqsUrl: 'https://stayforlong.elevio.help/fr/categories/30-questions-frequemment-posees',
    daysOfBooking: 1,
    defaultBookingDays: 3,
    inanitionDays: 0,
    chatbot: false,
  },
  us: {
    countryCode: 'us',
    countryLanguage: 'English',
    countryName: 'United States',
    contactEmail: 'support@cs.stayforlong.com',
    currency: 'USD',
    language: 'en',
    locale: 'en-US',
    market: 'us',
    phone: '+19493770263',
    measurementSystem: MEASUREMENT_UNITS.IMPERIAL,
    subdomain: null,
    helpCenterUrl: 'https://stayforlong.elevio.help/en',
    faqsUrl: 'https://stayforlong.elevio.help/en/categories/7-faq-s',
    daysOfBooking: 1,
    defaultBookingDays: 3,
    inanitionDays: 0,
    chatbot: false,
  },
  de: {
    countryCode: 'de',
    countryLanguage: 'Deutsch',
    countryName: 'Deutschland',
    contactEmail: 'larissa@cs.stayforlong.com',
    currency: 'EUR',
    language: 'de',
    locale: 'de-DE',
    market: 'de',
    phone: '+4906987001476',
    measurementSystem: MEASUREMENT_UNITS.METRIC,
    subdomain: 'de',
    helpCenterUrl: 'https://stayforlong.elevio.help/de',
    faqsUrl: 'https://stayforlong.elevio.help/de/categories/33-haeufig-gestellte-fragen',
    daysOfBooking: 1,
    defaultBookingDays: 3,
    inanitionDays: 0,
    chatbot: false,
  },
  it: {
    countryCode: 'it',
    countryLanguage: 'Italiano',
    countryName: 'Italia',
    contactEmail: 'giulia@cs.stayforlong.com',
    currency: 'EUR',
    language: 'it',
    locale: 'it-IT',
    market: 'it',
    phone: '+3908119020179',
    measurementSystem: MEASUREMENT_UNITS.METRIC,
    subdomain: null,
    helpCenterUrl: 'https://stayforlong.elevio.help/it',
    faqsUrl: 'https://stayforlong.elevio.help/it/categories/39-domande-frequenti',
    daysOfBooking: 1,
    defaultBookingDays: 3,
    inanitionDays: 0,
    chatbot: false,
  },
  es: {
    countryCode: 'es',
    countryLanguage: 'Español',
    countryName: 'España',
    contactEmail: 'claudia@cs.stayforlong.com',
    currency: 'EUR',
    language: 'es',
    locale: 'es-ES',
    market: 'es',
    phone: '+34911795385',
    measurementSystem: MEASUREMENT_UNITS.METRIC,
    subdomain: 'es',
    helpCenterUrl: 'https://stayforlong.elevio.help/es',
    faqsUrl: 'https://stayforlong.elevio.help/es/categories/26-preguntas-frecuentes',
    daysOfBooking: 1,
    defaultBookingDays: 3,
    inanitionDays: 0,
    chatbot: false,
  },
  nl: {
    countryCode: 'nl',
    countryLanguage: 'Nederlands',
    countryName: 'Nederland',
    contactEmail: 'sanne@cs.stayforlong.com',
    currency: 'EUR',
    language: 'nl',
    locale: 'nl-NL',
    market: 'nl',
    phone: '+31202625516',
    measurementSystem: MEASUREMENT_UNITS.METRIC,
    subdomain: null,
    helpCenterUrl: 'https://stayforlong.elevio.help/nl',
    faqsUrl: 'https://stayforlong.elevio.help/nl/categories/56-vaak-gestelde-vragen',
    daysOfBooking: 1,
    defaultBookingDays: 3,
    inanitionDays: 0,
    chatbot: false,
  },
  beFR: {
    countryCode: 'be',
    countryLanguage: 'Français',
    countryName: 'Belgique',
    contactEmail: 'gabrielle@cs.stayforlong.com',
    currency: 'EUR',
    language: 'fr',
    locale: 'fr-BE',
    market: 'be',
    phone: '+33428770117',
    measurementSystem: MEASUREMENT_UNITS.METRIC,
    subdomain: null,
    helpCenterUrl: 'https://stayforlong.elevio.help/fr',
    faqsUrl: 'https://stayforlong.elevio.help/fr/categories/30-questions-frequemment-posees',
    daysOfBooking: 1,
    defaultBookingDays: 3,
    inanitionDays: 0,
    chatbot: false,
  },
  beNL: {
    countryCode: 'be',
    countryLanguage: 'Nederlands',
    countryName: 'België',
    contactEmail: 'gabrielle@cs.stayforlong.com',
    currency: 'EUR',
    language: 'nl',
    locale: 'nl-BE',
    market: 'be',
    phone: '+33428770117',
    measurementSystem: MEASUREMENT_UNITS.METRIC,
    subdomain: null,
    helpCenterUrl: 'https://stayforlong.elevio.help/nl',
    faqsUrl: 'https://stayforlong.elevio.help/nl/categories/56-vaak-gestelde-vragen',
    daysOfBooking: 1,
    defaultBookingDays: 3,
    inanitionDays: 0,
    chatbot: false,
  },
  caEN: {
    countryCode: 'ca',
    countryLanguage: 'English',
    countryName: 'Canada',
    contactEmail: 'support@cs.stayforlong.com',
    currency: 'CAD',
    language: 'en',
    locale: 'en-CA',
    market: 'ca',
    phone: '+17808517445',
    measurementSystem: MEASUREMENT_UNITS.IMPERIAL,
    subdomain: null,
    helpCenterUrl: 'https://stayforlong.elevio.help/en',
    faqsUrl: 'https://stayforlong.elevio.help/en/categories/7-faq-s',
    daysOfBooking: 1,
    defaultBookingDays: 3,
    inanitionDays: 0,
    chatbot: false,
  },
  caFR: {
    countryCode: 'ca',
    countryLanguage: 'Français',
    countryName: 'Canada',
    contactEmail: 'support@cs.stayforlong.com',
    currency: 'CAD',
    language: 'fr',
    locale: 'fr-CA',
    market: 'ca',
    phone: '+17808517445',
    measurementSystem: MEASUREMENT_UNITS.IMPERIAL,
    subdomain: null,
    helpCenterUrl: 'https://stayforlong.elevio.help/fr',
    faqsUrl: 'https://stayforlong.elevio.help/fr/categories/30-questions-frequemment-posees',
    daysOfBooking: 1,
    defaultBookingDays: 3,
    inanitionDays: 0,
    chatbot: false,
  },
  dk: {
    countryCode: 'dk',
    countryLanguage: 'Dansk',
    countryName: 'Danmark',
    contactEmail: 'lucy@cs.stayforlong.com',
    currency: 'DKK',
    language: 'da',
    locale: 'da-DK',
    market: 'dk',
    phone: '+4532426610',
    measurementSystem: MEASUREMENT_UNITS.METRIC,
    subdomain: null,
    helpCenterUrl: 'https://stayforlong.elevio.help',
    faqsUrl: 'https://stayforlong.elevio.help/en/categories/7-faq-s',
    daysOfBooking: 1,
    defaultBookingDays: 3,
    inanitionDays: 0,
    chatbot: false,
  },
  gr: {
    countryCode: 'gr',
    countryLanguage: 'Eλληνικά',
    countryName: 'Ελλάδα',
    contactEmail: 'anastasia@cs.stayforlong.com',
    currency: 'EUR',
    language: 'el',
    locale: 'el-GR',
    market: 'gr',
    phone: '+302111984343',
    measurementSystem: MEASUREMENT_UNITS.METRIC,
    subdomain: null,
    helpCenterUrl: 'https://stayforlong.elevio.help',
    faqsUrl: 'https://stayforlong.elevio.help/en/categories/7-faq-s',
    daysOfBooking: 1,
    defaultBookingDays: 3,
    inanitionDays: 0,
    chatbot: false,
  },
  ie: {
    countryCode: 'ie',
    countryLanguage: 'English',
    countryName: 'Ireland',
    contactEmail: 'lucy@cs.stayforlong.com',
    currency: 'EUR',
    language: 'en',
    locale: 'en-IE',
    market: 'ie',
    phone: '+35315460751',
    measurementSystem: MEASUREMENT_UNITS.METRIC,
    subdomain: 'ie',
    helpCenterUrl: 'https://stayforlong.elevio.help/en',
    faqsUrl: 'https://stayforlong.elevio.help/en/categories/7-faq-s',
    daysOfBooking: 1,
    defaultBookingDays: 3,
    inanitionDays: 0,
    chatbot: false,
  },
  no: {
    countryCode: 'no',
    countryLanguage: 'Norsk',
    countryName: 'Norge',
    contactEmail: 'lucy@cs.stayforlong.com',
    currency: 'NOK',
    language: 'nn',
    locale: 'no-NN',
    market: 'no',
    phone: '+4723965735',
    measurementSystem: MEASUREMENT_UNITS.METRIC,
    subdomain: null,
    helpCenterUrl: 'https://stayforlong.elevio.help',
    faqsUrl: 'https://stayforlong.elevio.help/en/categories/7-faq-s',
    daysOfBooking: 1,
    defaultBookingDays: 3,
    inanitionDays: 0,
    chatbot: false,
  },
  at: {
    countryCode: 'at',
    countryLanguage: 'Deutsch',
    countryName: 'Österreich',
    contactEmail: 'johanna@cs.stayforlong.com',
    currency: 'EUR',
    language: 'de',
    locale: 'de-AT',
    market: 'at',
    phone: '+43720105209',
    measurementSystem: MEASUREMENT_UNITS.METRIC,
    subdomain: null,
    helpCenterUrl: 'https://stayforlong.elevio.help/de',
    faqsUrl: 'https://stayforlong.elevio.help/de/categories/33-haeufig-gestellte-fragen',
    daysOfBooking: 1,
    defaultBookingDays: 3,
    inanitionDays: 0,
    chatbot: false,
  },
  pl: {
    countryCode: 'pl',
    countryLanguage: 'Polski',
    countryName: 'Polska',
    contactEmail: 'lucy@cs.stayforlong.com',
    currency: 'PLN',
    language: 'pl',
    locale: 'pl-PL',
    market: 'pl',
    phone: '+48158881008',
    measurementSystem: MEASUREMENT_UNITS.METRIC,
    subdomain: null,
    helpCenterUrl: 'https://stayforlong.elevio.help/pl',
    faqsUrl: 'https://stayforlong.elevio.help/pl/categories/62-najcz-ciej-zadawane-pytania',
    daysOfBooking: 1,
    defaultBookingDays: 3,
    inanitionDays: 0,
    chatbot: false,
  },
  pt: {
    countryCode: 'pt',
    countryLanguage: 'Português',
    countryName: 'Portugal',
    contactEmail: 'isabel@cs.stayforlong.com',
    currency: 'EUR',
    language: 'pt',
    locale: 'pt-PT',
    market: 'pt',
    phone: '+351308800181',
    measurementSystem: MEASUREMENT_UNITS.METRIC,
    subdomain: null,
    helpCenterUrl: 'https://stayforlong.elevio.help/pt',
    faqsUrl: 'https://stayforlong.elevio.help/pt/categories/50-perguntas-mais-frequentes',
    daysOfBooking: 1,
    defaultBookingDays: 3,
    inanitionDays: 0,
    chatbot: false,
  },
  ro: {
    countryCode: 'ro',
    countryLanguage: 'Română',
    countryName: 'România',
    contactEmail: 'maria@cs.stayforlong.com',
    currency: 'RON',
    language: 'ro',
    locale: 'ro-RO',
    market: 'ro',
    phone: '+40316302322',
    measurementSystem: MEASUREMENT_UNITS.METRIC,
    subdomain: null,
    helpCenterUrl: 'https://stayforlong.elevio.help',
    faqsUrl: 'https://stayforlong.elevio.help/en/categories/7-faq-s',
    daysOfBooking: 1,
    defaultBookingDays: 3,
    inanitionDays: 0,
    chatbot: false,
  },
  chDE: {
    countryCode: 'ch',
    countryLanguage: 'Deutsch',
    countryName: 'Schweiz',
    contactEmail: 'emma@cs.stayforlong.com',
    currency: 'CHF',
    language: 'de',
    locale: 'de-CH',
    market: 'ch',
    phone: '+41435020028',
    measurementSystem: MEASUREMENT_UNITS.METRIC,
    subdomain: null,
    helpCenterUrl: 'https://stayforlong.elevio.help/de',
    faqsUrl: 'https://stayforlong.elevio.help/de/categories/33-haeufig-gestellte-fragen',
    daysOfBooking: 1,
    defaultBookingDays: 3,
    inanitionDays: 0,
    chatbot: false,
  },
  chFR: {
    countryCode: 'ch',
    countryLanguage: 'Français',
    countryName: 'Suisse',
    contactEmail: 'emma@cs.stayforlong.com',
    currency: 'CHF',
    language: 'fr',
    locale: 'fr-CH',
    market: 'ch',
    phone: '+41435020028',
    measurementSystem: MEASUREMENT_UNITS.METRIC,
    subdomain: null,
    helpCenterUrl: 'https://stayforlong.elevio.help/fr',
    faqsUrl: 'https://stayforlong.elevio.help/fr/categories/30-questions-frequemment-posees',
    daysOfBooking: 1,
    defaultBookingDays: 3,
    inanitionDays: 0,
    chatbot: false,
  },
  chIT: {
    countryCode: 'ch',
    countryLanguage: 'Italiano',
    countryName: 'Svizzera',
    contactEmail: 'emma@cs.stayforlong.com',
    currency: 'CHF',
    language: 'it',
    locale: 'it-CH',
    market: 'ch',
    phone: '+41435020028',
    measurementSystem: MEASUREMENT_UNITS.METRIC,
    subdomain: null,
    helpCenterUrl: 'https://stayforlong.elevio.help/it',
    faqsUrl: 'https://stayforlong.elevio.help/it/categories/39-domande-frequenti',
    daysOfBooking: 1,
    defaultBookingDays: 3,
    inanitionDays: 0,
    chatbot: false,
  },
  sk: {
    countryCode: 'sk',
    countryLanguage: 'Slovenský',
    countryName: 'Slovensko',
    contactEmail: 'viktoria@cs.stayforlong.com',
    currency: 'EUR',
    language: 'sk',
    locale: 'sk-SK',
    market: 'sk',
    phone: '+421232784592',
    measurementSystem: MEASUREMENT_UNITS.METRIC,
    subdomain: null,
    helpCenterUrl: 'https://stayforlong.elevio.help/sk',
    faqsUrl: 'https://stayforlong.elevio.help/sk/categories/70-platby-a-nahrady',
    daysOfBooking: 1,
    defaultBookingDays: 3,
    inanitionDays: 0,
    chatbot: false,
  },
  za: {
    countryCode: 'za',
    countryLanguage: 'English',
    countryName: 'South Africa',
    contactEmail: 'shona@cs.stayforlong.com',
    currency: 'ZAR',
    language: 'en',
    locale: 'en-ZA',
    market: 'za',
    phone: null,
    measurementSystem: MEASUREMENT_UNITS.METRIC,
    subdomain: null,
    helpCenterUrl: 'https://stayforlong.elevio.help/en',
    faqsUrl: 'https://stayforlong.elevio.help/en/categories/7-faq-s',
    daysOfBooking: 1,
    defaultBookingDays: 3,
    inanitionDays: 0,
    chatbot: true,
  },
  fi: {
    countryCode: 'fi',
    countryLanguage: 'Suomen kieli',
    countryName: 'Suomi',
    contactEmail: 'lucy@cs.stayforlong.com',
    currency: 'EUR',
    language: 'fi',
    locale: 'fi-FI',
    market: 'fi',
    phone: '+358931589665',
    measurementSystem: MEASUREMENT_UNITS.METRIC,
    subdomain: null,
    helpCenterUrl: 'https://stayforlong.elevio.help',
    faqsUrl: 'https://stayforlong.elevio.help/en/categories/7-faq-s',
    daysOfBooking: 1,
    defaultBookingDays: 3,
    inanitionDays: 0,
    chatbot: false,
  },
  se: {
    countryCode: 'se',
    countryLanguage: 'Svenska',
    countryName: 'Sverige',
    contactEmail: 'lucy@cs.stayforlong.com',
    currency: 'SEK',
    language: 'sv',
    locale: 'sv-SE',
    market: 'se',
    phone: '+46846501532',
    measurementSystem: MEASUREMENT_UNITS.METRIC,
    subdomain: null,
    helpCenterUrl: 'https://stayforlong.elevio.help',
    faqsUrl: 'https://stayforlong.elevio.help/en/categories/7-faq-s',
    daysOfBooking: 1,
    defaultBookingDays: 3,
    inanitionDays: 0,
    chatbot: false,
  },
  tr: {
    countryCode: 'tr',
    countryLanguage: 'Türkçe',
    countryName: 'Türkiye',
    contactEmail: 'lucy@cs.stayforlong.com',
    currency: 'TRY',
    language: 'tr',
    locale: 'tr-TR',
    market: 'tr',
    phone: null,
    measurementSystem: MEASUREMENT_UNITS.METRIC,
    subdomain: null,
    helpCenterUrl: 'https://stayforlong.elevio.help',
    faqsUrl: 'https://stayforlong.elevio.help/en/categories/7-faq-s',
    daysOfBooking: 1,
    defaultBookingDays: 3,
    inanitionDays: 0,
    chatbot: true,
  },
  au: {
    countryCode: 'au',
    countryLanguage: 'English',
    countryName: 'Australia',
    contactEmail: 'support@cs.stayforlong.com',
    currency: 'AUD',
    language: 'en',
    locale: 'en-AU',
    market: 'au',
    phone: null,
    measurementSystem: MEASUREMENT_UNITS.METRIC,
    subdomain: null,
    helpCenterUrl: 'https://stayforlong.elevio.help/en',
    faqsUrl: 'https://stayforlong.elevio.help/en/categories/7-faq-s',
    daysOfBooking: 1,
    defaultBookingDays: 3,
    inanitionDays: 3,
    chatbot: false,
  },
  mx: {
    countryCode: 'mx',
    countryLanguage: 'Español',
    countryName: 'Mexico',
    contactEmail: 'support@cs.stayforlong.com',
    currency: 'MXN',
    language: 'es',
    locale: 'es-MX',
    market: 'mx',
    phone: null,
    measurementSystem: MEASUREMENT_UNITS.METRIC,
    subdomain: null,
    helpCenterUrl: 'https://stayforlong.elevio.help/es',
    faqsUrl: 'https://stayforlong.elevio.help/es/categories/26-preguntas-frecuentes',
    daysOfBooking: 1,
    defaultBookingDays: 3,
    inanitionDays: 1,
    overrideCreditCardLogos: [PAYMENT_METHOD_VISA_ID, PAYMENT_METHOD_MASTERCARD_ID],
    chatbot: true,
  },
  br: {
    countryCode: 'br',
    countryLanguage: 'Português',
    countryName: 'Brasil',
    contactEmail: 'support@cs.stayforlong.com',
    currency: 'BRL',
    language: 'pt',
    locale: 'pt-BR',
    market: 'br',
    phone: null,
    measurementSystem: MEASUREMENT_UNITS.METRIC,
    subdomain: null,
    helpCenterUrl: 'https://stayforlong.elevio.help/es',
    faqsUrl: 'https://stayforlong.elevio.help/pt/categories/50-perguntas-mais-frequentes',
    daysOfBooking: 1,
    defaultBookingDays: 3,
    inanitionDays: 1,
    chatbot: true,
    overrideCreditCardLogos: [PAYMENT_METHOD_VISA_ID, PAYMENT_METHOD_MASTERCARD_ID],
  },
  zz: {
    countryCode: 'zz',
    countryLanguage: 'English',
    countryName: 'zz',
    contactEmail: '',
    currency: 'EUR',
    language: 'en',
    locale: 'en-IE',
    market: 'ie',
    phone: null,
    measurementSystem: MEASUREMENT_UNITS.METRIC,
    subdomain: null,
    helpCenterUrl: 'https://stayforlong.elevio.help/en',
    faqsUrl: 'https://stayforlong.elevio.help/en/categories/7-faq-s',
    daysOfBooking: 1,
    defaultBookingDays: 3,
    inanitionDays: 0,
    chatbot: true,
  },
};

export const DEFAULT_COUNTRY = COUNTRIES.us;

export const CUSTOM_DOMAINS = [
  'de.stayforlong.com',
  'es.stayforlong.com',
  'ie.stayforlong.com',
  'stayforlong.co.uk',
  'stayforlong.de',
  'stayforlong.fr',
  'www.stayforlong.co.uk',
  'www.stayforlong.de',
  'www.stayforlong.fr',
];
