import styled, { css } from 'styled-components';
import { fonts } from 'client/styles';

export const LoginButtonBase = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export const LoginLink = styled.a`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const LoginLabel = styled.div<{ isEbooking: boolean }>`
  height: 21px;
  line-height: 21px;
  font-size: 14px;
  font-weight: 700;
  font-family: ${fonts.default};
  color: var(--color--neutral--800);
  ${props =>
    props.isEbooking &&
    css`
      color: var(--color--base--white);
    `}
`;

export const CheckoutLabel = styled(LoginLabel)`
  color: var(--color--neutral--600);
`;
