import React, { useContext, useEffect, useState, MouseEvent, useRef } from 'react';

import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import Image from 'src/comps/Image';
import { useRouter } from 'next/router';
import { THEME_DEFAULT, THEMES } from 'client/components/Header/constants';

import { clickAuthentication } from 'client/contexts/Tracking/events';
import { logoutRequestAction } from 'client/contexts/Login/actions';
import { PageContext } from 'src/comps/Page/context';
import { buildEmailHash } from 'client/contexts/Tracking/utils';
import { sendTrackingRequestAction } from 'client/contexts/Tracking/actions';

import IconPerson from 'client/icons/Person';
import LoginModal from 'src/comps/LoginModal';

import { useSession } from 'src/hooks/useSession';
import { LoginButtonBase, LoginLabel, LoginLink, CheckoutLabel } from './styles';
import { addRedirect } from 'src/comps/LoginButton/utils';

import noop from 'lodash/noop';
import { useLogin } from 'src/hooks/useLogin/useLogin';
import { useClickAway } from 'react-use';
import { useDevice } from 'src/hooks/useDevice';
import { BreakpointSizes } from 'src/types/device';
import IconButon from '../Atom/IconButton';

type LoginButtonProps = {
  isDesktop?: boolean;
  theme?: (typeof THEMES)[number];
  onClick?: () => void;
  onClose?: () => void;
  show?: boolean;
  isEbooking?: boolean;
  small?: boolean;
};

const LoginButton = ({
  isDesktop,
  theme = THEME_DEFAULT,
  onClick = noop,
  onClose = noop,
  show = false,
  isEbooking = false,
  small = false,
}: LoginButtonProps) => {
  useLogin();

  const ref = useRef<HTMLDivElement | null>(null);

  const { apiHostName, pageId } = useContext(PageContext);
  const router = useRouter();
  const dispatch = useDispatch();
  const { activeBreakpoint } = useDevice();
  const isTablet = activeBreakpoint >= BreakpointSizes.M && activeBreakpoint < BreakpointSizes.L;
  const isMobile = activeBreakpoint < BreakpointSizes.M;

  const { isLogged, customer } = useSession();

  const [isModalOpen, setModalOpen] = useState(show);

  const hashEmail = isLogged ? buildEmailHash(customer?.email, 'sha-256') : '';
  const loggedUserIconL = `${process.env.NEXT_PUBLIC_CLOUDFRONT_ENDPOINT}/images/login-dark-icon-l.png`;
  const loggedUserIconS = `${process.env.NEXT_PUBLIC_CLOUDFRONT_ENDPOINT}/images/login-dark-icon-s.png`;
  const handleClick = <T extends HTMLElement>(event: MouseEvent<T>) => {
    event?.stopPropagation();
    if (!isModalOpen) {
      onClick();
    }
    setModalOpen(!isModalOpen);
    dispatch(sendTrackingRequestAction({ event: clickAuthentication() }));
  };

  const handleClose = (event: MouseEvent<HTMLAnchorElement>) => {
    event?.stopPropagation();
    setModalOpen(false);
    onClose();
    dispatch(sendTrackingRequestAction({ event: clickAuthentication() }));
  };

  const handleLogOut = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    event.stopPropagation();

    setModalOpen(false);
    onClose();
    addRedirect(router, pageId);

    dispatch(logoutRequestAction({ hostname: apiHostName }));
  };

  useEffect(() => {
    setModalOpen(false);
    onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogged]);

  useEffect(() => {
    setModalOpen(show);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  useClickAway(ref, () => {
    if (isMobile) {
      setModalOpen(false);
      onClose();
    }
  });

  return (
    <LoginButtonBase data-testid="header-login" ref={ref}>
      <LoginLink onClick={handleClick} theme={theme}>
        <IconButon
          onClick={handleClick}
          dataTestId="header-login-button"
          theme={theme}
          small={small}
          largeExtraWidth={isDesktop && !isLogged}
        >
          {isLogged ? (
            <Image
              src={`https://gravatar.com/avatar/${hashEmail}?d=${encodeURIComponent(
                isTablet ? loggedUserIconL : loggedUserIconS
              )}`}
              alt="avatar"
            />
          ) : (
            <>
              <IconPerson size={14} />
              {isDesktop && !small && (
                <LoginLabel isEbooking={isEbooking}>
                  <FormattedMessage id="global.login" />
                </LoginLabel>
              )}
            </>
          )}
        </IconButon>
        {small && (
          <CheckoutLabel isEbooking={isEbooking}>
            <FormattedMessage id="global.login" />
          </CheckoutLabel>
        )}
      </LoginLink>
      <LoginModal
        handleClose={handleClose}
        handleLogOut={handleLogOut}
        isLoggedIn={isLogged}
        isOpen={isModalOpen}
      />
    </LoginButtonBase>
  );
};

export default LoginButton;
