/* eslint-disable camelcase */
interface IPaymentMultiMethodRaw {
  klarna?: { provider?: string; params?: object };
  giropay?: { provider?: string; params?: object };
  ideal?: { provider?: string; params?: object };
  multibanco?: { provider?: string; params?: object };
  bancontact?: { provider?: string; params?: object };
  credit_card: { provider?: string; params: { payment_url: string } };
  worldline_direct: {
    payment_url: string;
  }; // legacy payment method included in multi-method response to aid crossover to new payment methods
}

interface IPaymentAPMRaw {
  paycomet_klarna?: string; // Paycomet klarna
  paycomet_giropay?: string; // Paycomet giropay
  paycomet_ideal?: string; // Paycomet ideal
  paycomet_multibanco?: string; // Paycomet multibanco
  paycomet_bancontact?: string; // Paycomet bancontact
}

interface IPaymentMethodWordlineRaw extends IPaymentAPMRaw {
  worldline_direct: {
    payment_url: string;
  };
}

interface IPaymentMethodOgoneRaw extends IPaymentAPMRaw {
  // Ogone
  flexcheckout: {
    payment_url: string;
  };
}

interface IPaymentMethodPaycometRaw extends IPaymentAPMRaw {
  paycomet: {
    jetID: string;
  };
}

export type PaymentMethodRaw = IPaymentMethodOgoneRaw &
  IPaymentMethodWordlineRaw &
  IPaymentMethodPaycometRaw &
  IPaymentMultiMethodRaw;

export enum PaymentMethodEnum {
  Ogone = 'ogone',
  Worldline = 'worldline_direct',
  Paycomet = 'paycomet',
}

enum APMKindEnum {
  Klarna = 'klarna',
  Giropay = 'giropay',
  Ideal = 'ideal',
  Multibanco = 'multibanco',
  Bancontact = 'bancontact',
}

enum APMValuesEnum {
  Klarna = 'paycomet_klarna',
  Giropay = 'paycomet_giropay',
  Ideal = 'paycomet_ideal',
  Multibanco = 'paycomet_multibanco',
  Bancontact = 'paycomet_bancontact',
}

interface IAPM {
  kind: APMKindEnum;
  value: APMValuesEnum;
}

interface IPaymentMethodWorldline {
  kind: PaymentMethodEnum.Worldline;
  creditCard: {
    url: string;
  };
  apm?: IAPM[];
}

interface IPaymentMethodOgone {
  kind: PaymentMethodEnum.Ogone;
  creditCard: {
    url: string;
  };
  apm?: IAPM[];
}

interface IPaymentMethodPaycomet {
  kind: PaymentMethodEnum.Paycomet;
  creditCard: {
    clientId: string;
  };
  apm?: IAPM[];
}

export function mapMultiMethodAPMs(data: IPaymentMultiMethodRaw): IAPM[] {
  const apm = [];
  if (data.klarna) {
    apm.push({ kind: APMKindEnum.Klarna, value: APMValuesEnum.Klarna });
  }
  if (data.giropay) {
    apm.push({ kind: APMKindEnum.Giropay, value: APMValuesEnum.Giropay });
  }
  if (data.ideal) {
    apm.push({ kind: APMKindEnum.Ideal, value: APMValuesEnum.Ideal });
  }
  if (data.multibanco) {
    apm.push({ kind: APMKindEnum.Multibanco, value: APMValuesEnum.Multibanco });
  }
  if (data.bancontact) {
    apm.push({ kind: APMKindEnum.Bancontact, value: APMValuesEnum.Bancontact });
  }
  return apm;
}

export function mapLegacyAPMs(data: IPaymentAPMRaw): IAPM[] {
  const apm = [];
  if (data.paycomet_klarna) {
    apm.push({ kind: APMKindEnum.Klarna, value: APMValuesEnum.Klarna });
  }
  if (data.paycomet_giropay) {
    apm.push({ kind: APMKindEnum.Giropay, value: APMValuesEnum.Giropay });
  }
  if (data.paycomet_ideal) {
    apm.push({ kind: APMKindEnum.Ideal, value: APMValuesEnum.Ideal });
  }
  if (data.paycomet_multibanco) {
    apm.push({ kind: APMKindEnum.Multibanco, value: APMValuesEnum.Multibanco });
  }
  if (data.paycomet_bancontact) {
    apm.push({ kind: APMKindEnum.Bancontact, value: APMValuesEnum.Bancontact });
  }
  return apm;
}

// map credit card (multi-method payments)
function mapCreditCard(data: IPaymentMultiMethodRaw): IPaymentMethodWorldline {
  return {
    kind: PaymentMethodEnum.Worldline,
    creditCard: {
      url: data.credit_card.params.payment_url,
    },
    apm: mapMultiMethodAPMs(data),
  };
}

// map legacy payment methods
function mapOgone(data: IPaymentMethodOgoneRaw): IPaymentMethodOgone {
  return {
    kind: PaymentMethodEnum.Ogone,
    creditCard: {
      url: data.flexcheckout.payment_url,
    },
    apm: mapLegacyAPMs(data),
  };
}

function mapWorldline(data: IPaymentMethodWordlineRaw): IPaymentMethodWorldline {
  return {
    kind: PaymentMethodEnum.Worldline,
    creditCard: {
      url: data.worldline_direct.payment_url,
    },
    apm: mapLegacyAPMs(data),
  };
}

function mapPaycomet(data: IPaymentMethodPaycometRaw): IPaymentMethodPaycomet {
  return {
    kind: PaymentMethodEnum.Paycomet,
    creditCard: {
      clientId: data.paycomet.jetID,
    },
    apm: mapLegacyAPMs(data),
  };
}

export type PaymentMethods = IPaymentMethodOgone | IPaymentMethodWorldline | IPaymentMethodPaycomet;

export function mapPaymentMethods(data: PaymentMethodRaw): PaymentMethods {
  // multi-method payment method
  if (data.credit_card?.params?.payment_url) {
    return mapCreditCard(data);
  }

  // older payment methods
  if (data.flexcheckout?.payment_url) {
    return mapOgone(data);
  }
  if (data.paycomet?.jetID) {
    return mapPaycomet(data);
  }
  if (data.worldline_direct?.payment_url) {
    return mapWorldline(data);
  }

  throw new Error('Invalid payment method');
}
