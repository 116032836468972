interface Params {
  propertiesIds: number[];
  language: string;
}

export const buildPropertiesVisitedRequest = (params: Params) => {
  return {
    id_properties: params.propertiesIds.join(),
    lang: params.language,
  };
};
