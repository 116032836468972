import murmur from 'murmurhash-js';

import { useMarket } from 'src/hooks/useMarket';
import { useDevice } from 'src/hooks/useDevice';
import { useSession } from 'src/hooks/useSession';
import { useBusiness } from 'src/hooks/useBusiness';
import { PAGES, PAGES_KEYS, SECTION_KEYS } from './constants';
import { ORIGIN_DIRECT } from 'client/contexts/Tracking/constants';

const SEED = 87;
const EVENT_NAME = 'click';
const EVENT_TYPE = 'click';
const LEVEL = 'info';
const EVENT_SUB_TYPE = null;
const SOURCE = 'frontend';
const SCOPE = 'tracking';

export function trackingKafkaRequest(data: Record<string, any>) {
  fetch(`${process.env.NEXT_PUBLIC_TRACKING_ENDPOINT}`, {
    method: 'POST',
    body: JSON.stringify({
      records: [{ key: null, value: data }],
    }),
    headers: {
      'Content-Type': 'application/vnd.kafka.json.v2+json',
      Accept: 'application/vnd.kafka.v2+json',
    },
  });
}

interface UseKafkaEventParams {
  eventName?: string;
  eventType?: string;
  eventSubType?: string | null;
  page: string;
}

export const useKafkaEvent = ({
  eventName = EVENT_NAME,
  eventType = EVENT_TYPE,
  eventSubType = EVENT_SUB_TYPE,
  page,
}: UseKafkaEventParams) => {
  const { language, market } = useMarket();
  const { device, ip, isBot } = useDevice();
  const { customer, id } = useSession();
  const { affiliate } = useBusiness();

  const pageKey = typeof page === 'string' ? page.toUpperCase() : page;
  const pageName = PAGES_KEYS.includes(pageKey) ? PAGES[pageKey as keyof typeof PAGES] : page;

  const sendEvent = (
    { section }: { section: (typeof SECTION_KEYS)[number] },
    extraPayload: Record<string, any> = {}
  ) => {
    const timestamp = Date.now();
    const kafkaData = {
      level: LEVEL,
      event_sub_type: eventSubType,
      source: SOURCE,
      event_type: eventType,
      scope: SCOPE,
      event_name: eventName,
      hsl: murmur(`${eventName}${timestamp}${eventType}`, SEED),
      ip,
      is_bot: isBot,
      created_at: timestamp,
      screen: `${window.screen.width}x${window.screen.height}`,
      session_id: id,
      language,
      market: market.toUpperCase(),
      user_id: customer?.userId ?? null,
      device,
      user_agent: navigator.userAgent,
      payload: {
        affiliate,
        origin: document.referrer ? new URL(document.referrer).origin : ORIGIN_DIRECT,
        page: pageName,
        section,
        ...extraPayload,
      },
    };

    trackingKafkaRequest(kafkaData);
  };

  return { sendEvent };
};
