/* eslint-disable camelcase */
import { FACILITIES_GROUPS_EXCLUDED } from './constants';
import { CENTER_RADIUS } from 'src/comps/Property/constants';
import getSafe from 'lib/functions/getSafe';
import isValidArray from 'lib/functions/isValidArray';

function mapSearchFacilities(facilities) {
  return facilities.map(facility => ({
    name: facility.id,
    icon: facility.id,
  }));
}

export function mapFacilities(facilities, grouped) {
  if (!facilities) {
    return [];
  }

  const allFacilities = grouped
    ? facilities.reduce((all, group) => {
        const { code, description } = group;

        /** @todo : backend should return these facilities filtered */
        if (FACILITIES_GROUPS_EXCLUDED.includes(code)) {
          return all;
        }

        const facilitiesWithCategory = group.facilities.map(facility => ({
          ...facility,
          categoryName: description,
          categoryCode: code || null,
        }));

        return all.concat(facilitiesWithCategory);
      }, [])
    : facilities;

  return allFacilities
    .filter(facility => facility.name)
    .map(facility => {
      const { icon, name, categoryCode, categoryName, featured, free, alias, id } = facility;
      const groupData = categoryCode ? { categoryCode, categoryName } : {};

      return {
        name,
        icon:
          (icon && /base64-icon/.test(icon) && icon.replace('base64-icon ', '')) ||
          alias ||
          id ||
          'done',
        featured,
        free,
        ...groupData /** @todo: Cleanup the API response to provide category directly */,
      };
    });
}

export function mapConditions(conditions) {
  if (!conditions) {
    return {};
  }

  const { checkin, checkout, fees, policies } = conditions;

  return {
    checkin: {
      beginTime: checkin?.begin_time || '',
      endTime: checkin?.end_time || '',
      instructions: checkin?.instructions || '',
      specialInstructions: checkin?.special_instructions || '',
      minAge: checkin?.min_age || 0,
    },
    checkout: {
      time: checkout?.time || '',
    },
    fees: {
      mandatory: fees?.mandatory || '',
      optional: fees?.optional || '',
    },
    policies: {
      knowBeforeYouGo: policies?.know_before_you_go || '',
    },
  };
}

function mapRatings(ratings) {
  const { rating, subratings, rating_count: count } = ratings;

  if (!rating) {
    return {};
  }

  const subScores = subratings.map(item => ({
    name: item.name,
    value: item.value,
    localizedName: item.localized_name,
  }));

  return {
    score: rating ? parseFloat(ratings.rating.toFixed(1)) : null,
    subScores,
    count,
    TAReviewId: ratings.id_tripadvisor,
  };
}

function mapRating(data) {
  if (typeof data === 'number') {
    return parseFloat(data);
  }

  if (typeof data === 'object' && data.rating) {
    return data.rating;
  }

  return null;
}

function mapDescription(data) {
  if (!data) {
    return null;
  }

  return {
    generic: data.generic || null,
    amenities: data.amenities || null,
    dining: data.dining || null,
    renovations: data.renovations || null,
    businessAmenities: data.business_amenities || null,
    rooms: data.rooms || null,
    attractions: data.attractions || null,
    location: data.location || null,
    general: data.general || null,
    pool: data.pool || null,
    activity: data.activity || null,
    additional: data.additional || null,
  };
}

export function mapDataToProperty(data) {
  const language = data.language || 'en';
  const neighborhood = data.locations?.find(location => location.location_type === 'neighborhood');

  const photos = isValidArray(data.images)
    ? data.images.map(({ available_paths, id, width, height, code, categoryOrder }) => ({
        id,
        width,
        height,
        code,
        availablePaths: available_paths || [],
        categoryOrder,
      }))
    : [];

  const photo = photos.length > 0 ? photos[0] : {};

  const facilities = isValidArray(data.facility_groups)
    ? mapFacilities(data.facility_groups, true)
    : mapFacilities(data.facilities);

  const facilitiesRoom = isValidArray(data.facilities_room)
    ? mapFacilities(data.facilities_room)
    : [];

  const cancellationPolicies = getSafe(
    () => data.availability[0].rooms[0].boards[0].cancellation_policies,
    []
  );

  return {
    accommodationType: data.accommodation_type,
    address: data.address || null,
    cancellation: cancellationPolicies.length === 0,
    city: data.city,
    citySlug: data.location?.city_slug || data.city_slug || '',
    cityUrl: data.city_url || null,
    comments: data.comments || 0,
    conditions: mapConditions(data.conditions),
    country: data.country || null,
    description: mapDescription(data.description?.[language]),
    distance: data.distance,
    facilities: isValidArray(facilities) ? facilities : [],
    facilitiesRoom: isValidArray(facilitiesRoom) ? facilitiesRoom : [],
    highlighted: data.highlighted || false,
    id: parseInt(data.id_property, 10),
    isInCenter: data.distance != null ? CENTER_RADIUS > data.distance : null,
    isOutsideCity: data.is_outside_city || null,
    latitude: parseFloat(data.latitude),
    longitude: parseFloat(data.longitude),
    name: data.name,
    neighborhood: neighborhood?.location_name || null,
    newPath: data.new_path || null,
    photo,
    photos,
    postalCode: data.postal_code || null,
    price: data.total_selling_rate || null,
    priceOld: data.total_bighead_rate || null,
    ratings: (data.ratings && mapRatings(data.ratings)) || null,
    score: mapRating(data.rating || data.ratings),
    searchTerm: data.location?.text || null,
    slug: data.slug,
    stars: parseInt(data.stars, 10),
    url: data.url || null,
  };
}

export function mapDataSearchToProperty(data) {
  const photos = isValidArray(data.images)
    ? data.images.map(({ available_paths, id, width, height, code }) => ({
        id,
        width,
        height,
        code,
        availablePaths: available_paths || [],
      }))
    : [];
  const photo = photos.length > 0 ? photos[0] : {};
  const facilities = isValidArray(data.facilities) ? mapSearchFacilities(data.facilities) : [];
  const cancellation = getSafe(() => data.rooms[0].is_free_cancellation, false);
  const distanceUnit = data.distance_to_center?.unit || 'm';
  const distanceValue = data.distance_to_center?.value || null;
  const distanceInKms = distanceUnit === 'm' ? distanceValue / 1000 : distanceValue;
  const distance = distanceValue != null ? distanceInKms : null;
  const isPrime = getSafe(() => data.rooms[0].rate_discounts.prime, false);
  const isMobile = getSafe(() => data.rooms[0].rate_discounts.mobile, false);
  const city = data.locations?.find(location => location.type === 'city');
  const neighborhood = data.locations?.find(location => location.type === 'neighborhood');

  let additionalFees = 0;
  let priceWithFees = 0;
  let pricePerNightWithFees = 0;
  let subtotal = 0;
  let pricePerNightSubtotal = 0;

  data?.rooms?.forEach(room => {
    const summary = room?.summary;
    additionalFees += summary?.additional_fees?.amount ?? 0;
    priceWithFees += summary?.total_with_fees?.amount ?? 0;
    pricePerNightWithFees += summary?.total_nightly_with_fees?.amount ?? 0;
    subtotal += summary?.subtotal?.amount ?? 0;
    pricePerNightSubtotal += summary?.subtotal_nightly?.amount ?? 0;
  });

  return {
    accommodationType: data.accommodation_type,
    additionalFees,
    address: data.address,
    cancellation,
    city: city?.name || data.city,
    citySlug: city?.name_slug || data.city_slug || '',
    cityUrl: '',
    comments: data.comments || data.ratings?.rating_count || 0,
    country: data.property_location?.country_code || data.country || null,
    currency: data.currency,
    description: '',
    distance: distance || data.distance || null,
    facilities: isValidArray(facilities) ? facilities : [],
    facilitiesRoom: [],
    highlighted: data.highlighted || false,
    id: parseInt(data.id || data.id_property, 10), // TODO: backend has to unify all properties responses
    isInCenter: distance != null ? CENTER_RADIUS > distance : data.in_center,
    isMobile,
    isOutsideCity: data.is_outside_city || null,
    isPrime,
    latitude: data.property_location?.coordinates.lat || parseFloat(data.coordinates?.lat),
    longitude: data.property_location?.coordinates.lon || parseFloat(data.coordinates?.lon),
    name: data.name,
    neighborhood: neighborhood?.name || null,
    nights: data.nights,
    photo,
    photos,
    postalCode: null,
    price: data.selling_rate,
    priceOld: data.bighead_rate,
    pricePerNight: data.avg_rate || null,
    pricePerNightSubtotal: pricePerNightSubtotal || null,
    pricePerNightWithFees: pricePerNightWithFees || data.avg_rate || null,
    priceWithFees: priceWithFees || data.selling_rate,
    ratings: null,
    rooms: data.rooms || null,
    score: mapRating(data.rating || data.ratings),
    searchTerm: null,
    slug: data.slug,
    stars: parseInt(data.stars, 10),
    subtotal: subtotal || null,
    url: null,
  };
}

export function mapDataToPropertiesCollection(collection) {
  return collection.map(property => mapDataToProperty(property));
}

export function mapSearchDataToPropertiesCollection(collection) {
  return collection.map(property => mapDataSearchToProperty(property));
}

export function mapProperties(data) {
  return mapSearchDataToPropertiesCollection(data.properties);
}
