import { useContext } from 'react';
import { PageContext } from 'src/comps/Page/context';

export const usePage = () => {
  const page = useContext(PageContext);

  if (!page) {
    throw new Error('Missing PageContext provider');
  }

  return page;
};
