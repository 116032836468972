export const SECTIONS = {
  DESTINATION_BOX: 'destination_box',
  CHECKIN_BOX: 'checkin_box',
  CHECKOUT_BOX: 'checkout_box',
  ROOM_IMAGES: 'room_images',
  HOTEL_IMAGES: 'hotel_images',
  PAXES_BOX: 'paxes_box',
  SEARCH_FILTERS: 'search_filters',
  RELATED: 'related',
  MAP_RESULTS: 'map_result',
  LIST_RESULTS: 'list_result_item',
  ROOM_OFFERS: 'room_offers',
  SHOW_MORE_ROOMS: 'show_more_rooms',
  BANNER: 'banner',
  CHANGE_PAXES_DATES: 'change_paxes_dates',
  HOTELS_SEEN: 'hotels_seen',
  SUGGESTER: 'suggester',
  SUGGESTION: 'suggestion',
  CHATBOT: 'chatbot',
} as const;

export const PAGES = {
  LIST: 'hotel_list_results',
  HOME: 'homepage',
  DETAIL: 'hotel_details',
};

export const SECTION_KEYS = Object.values(SECTIONS);
export const PAGES_KEYS = Object.keys(PAGES);

export type SectionType = (typeof SECTION_KEYS)[number];
