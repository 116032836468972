import React from 'react';

const LogoMobile = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M33.7972 11.3189C35.1301 8.45251 35.8544 5.62233 35.8544 4H30.0904C30.0904 4.66107 29.8691 5.63393 29.487 6.73012C23.9798 3.35441 19.695 4.71463 17.4289 7.2937C14.7363 10.3582 14.722 15.2039 17.3989 17.8753C19.4651 19.9411 21.5216 20.5409 23.2183 20.5409C24.2034 20.536 25.1781 20.3413 26.0878 19.9676C27.2209 19.5073 28.2704 18.7901 29.2291 17.9056C29.2748 18.4757 29.2944 19.0586 29.2944 19.6487C29.2944 24.9676 26.018 29.6073 20 29.6073C14.05 29.6073 10.7076 24.9676 10.7076 19.6487C10.7076 16.4389 11.9589 13.466 14.2831 11.632L10.8591 6.51257C6.48359 9.3079 4.00195 14.1574 4.00195 19.6487C4.00195 28.1845 10.0676 35.3593 20.002 35.3593C29.9363 35.3593 36.002 28.1845 36.002 19.6487C35.9974 16.5938 35.2307 13.7359 33.7972 11.3189ZM23.7851 16.1523C23.0657 16.1523 22.3624 15.9414 21.7642 15.5463C21.166 15.1511 20.6998 14.5895 20.4245 13.9325C20.1492 13.2754 20.0771 12.5524 20.2175 11.8549C20.3578 11.1574 20.7043 10.5167 21.213 10.0138C21.7217 9.51089 22.3699 9.16842 23.0755 9.02967C23.7811 8.89092 24.5125 8.96213 25.1772 9.2343C25.8419 9.50646 26.41 9.96735 26.8097 10.5587C27.2094 11.15 27.4227 11.8452 27.4227 12.5564C27.4227 13.5101 27.0395 14.4247 26.3573 15.0991C25.6751 15.7734 24.7499 16.1523 23.7851 16.1523Z"
      fill="#F60E5F"
    />
    <path
      style={{ mixBlendMode: 'multiply' }}
      opacity="0.25"
      d="M29.1752 17.9482C30.5159 16.4266 33.3698 12.4609 33.7982 11.3143C34.6662 12.805 36.0134 15.5274 35.9984 19.8327C35.9938 21.1051 28.8911 22.9656 29.0799 21.9469C29.394 20.2536 29.2849 18.5473 29.1752 17.9482Z"
      fill="url(#paint0_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="29.6617"
        y1="14.0832"
        x2="34.426"
        y2="21.2019"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#212120" />
        <stop offset="0.2" stopColor="#212120" stopOpacity="0.72" />
        <stop offset="0.51" stopColor="#212120" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);

export default LogoMobile;
